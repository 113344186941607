<div class="push-bottom" *ngIf="{
    groupLeaders: groupLeaders | async,
    currentUserRole: currentUserRole | async
  } as data">
  <div class="group-leaders row" *ngIf="data.groupLeaders">
    <div class="
        group-leaders__leader
        col-xs-12 col-sm-6 col-md-4
        soft-quarter-ends
      " *ngFor="let groupLeader of data.groupLeaders">


      <div class="user-avatar push-quarter-ends">

        <ng-template [ngIf]="groupLeader.imageUrl != 'https://crossroads-media.imgix.net/images/avatar.svg'"
          [ngIfElse]="noAvatarImg">
          <div class="user-avatar__thumb"
            [ngStyle]="{'background-image': 'url(' + groupLeader.imageUrl + ')'}"></div>
        </ng-template>

        <ng-template #noAvatarImg>
          <div class="user-avatar__thumb" [style.background-color]="groupLeader.backgroundColor"></div>
          <div class="user-avatar__no-img"></div>
          <div class="user-avatar__initials">
            {{groupLeader.nickName|slice:0:1 }}{{groupLeader.lastName|slice:0:1}}
          </div>
        </ng-template>

      </div>

      <p class="flush-top push-half-bottom">
        <b class="group-leaders__label font-family-serif">LEADER</b>
        <br />
        {{ groupLeader.nickName }}
        {{
          data.currentUserRole
            ? groupLeader.lastName
            : (groupLeader.lastName | firstLetter)
        }}
      </p>
    </div>
  </div>
</div>
