<div
  *ngIf="{
    groupSaveResponse: groupSaveResponse | async,
    currentGroup: currentGroup | async,
    isHosted: isHosted | async
  } as data"
>
  <div *ngIf="data.groupSaveResponse && data.currentGroup">
    <crds-modal
      [label]="data.currentGroup | saveResponseTitle: data.groupSaveResponse"
      [isActive]="data.groupSaveResponse"
      [preventClose]="true"
      [appendToBody]="false"
    >
      <br />
      <div *ngIf="!data.groupSaveResponse.isEdit; else updatedGroup">
        <div *ngIf="data.currentGroup.availableOnline; else notYetVisible">
          Nice work! Your group has been created. From this page you can view,
          edit and manage your group's information.
        </div>
        <ng-template #notYetVisible>
          Your group has been saved, but it is
          <span class="emphasized font-family-condensed">
            not yet visible to the public.</span
          >
        </ng-template>
      </div>

      <ng-template #updatedGroup>
        <div>
          Nice work! Your group has been updated. From this page you can view,
          edit and manage your group's information.
        </div>
      </ng-template>

      <div *ngIf="data.groupSaveResponse.status?.length > 1 || data.isHosted">
        <br />
        <hr />
        <div class="h5 font-family-condensed text-uppercase">Next Steps</div>

        <div
          *ngIf="
            data.groupSaveResponse.status
              | groupHasStatus: groupCreationStatus.PendingParentApproval
          "
        >
          <p>
            A staff member will reach out to approve your request for a new
            Crossroads Hosted Group ({{
              data.currentGroup?.parentGroup?.name
            }}).
          </p>
        </div>
        <div
          *ngIf="
            (data.groupSaveResponse.status
              | groupHasStatus
                : groupCreationStatus.PendingAdditionalServicesSetup)
          "
        >
          <p>
            To complete the process, a staff member will reach out to assist with 
            <span class="emphasized font-family-condensed">
              payment and additional requests.
            </span> 
            <span *ngIf="!data.currentGroup.availableOnline">
              Once the process is complete, your group will be made visible to the public.
            </span>
          </p>
        </div>
        <div
          *ngIf="
            data.groupSaveResponse.status
              | groupHasStatus: groupCreationStatus.PendingBackgroundCheck
          "
        >
          <p>
            A staff member will reach out to assist with
            <span class="emphasized font-family-condensed"
              >confirming your background check</span
            >
            since you indicated wanting to lead middle school or high school
            students.
          </p>
        </div>
        <div
          *ngIf="
            data.groupSaveResponse.status
              | groupHasStatus: groupCreationStatus.ChildcareReminder
          "
        >
          <p>
            Since you indicated that your group will offer childcare services,
            remember to
            <a href="/childcare/childcare-request-form"
              >submit your childcare request here</a
            >, if you have not already done so.
          </p>
        </div>
        <p
          *ngIf="
            !data.groupSaveResponse.isEdit && !data.currentGroup.availableOnline
          "
        >
          Once the process is complete, your group will be made visible to the
          public.
        </p>
        <div *ngIf="data.isHosted">
          <p>
            If your group is meeting in a Crossroads building,
            <a
              href="https://admin.crossroads.net/crds-mp-tools/events/createedit"
              >click here to reserve a room</a
            >.
          </p>
        </div>
      </div>

      <div
        class="push-half-top push-quarter-bottom"
        style="display: 'flex'; justify-content: 'flex-start'"
      >
        <crds-button
          color="blue"
          display="solid"
          text="View my group"
          (click)="close()"
        ></crds-button>
      </div>
    </crds-modal>
  </div>
</div>
