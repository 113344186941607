import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  currentGroup,
  currentGroupParticipants,
} from 'src/app/shared/state/state.resolver';
import {
  AcceptRequestGQL,
  CurrentUserFragment,
  DenyRequestGQL,
  GroupParticipantFragment,
  GroupViewFragment,
  JoinRequestFragment,
} from 'src/generated/graphql';
import { cloneDeep } from '@apollo/client/utilities';

@Component({
  selector: 'app-accept-deny-request',
  templateUrl: './accept-deny-request.component.html',
  styleUrls: ['./accept-deny-request.component.scss'],
})
export class AcceptDenyRequestComponent {
  @Input() currentUser: CurrentUserFragment;
  @Input() group: GroupViewFragment;
  @Input() request: JoinRequestFragment;
  @Input() participants: GroupParticipantFragment[];
  disable: boolean = false;

  constructor(
    private acceptRequestMutation: AcceptRequestGQL,
    private denyRequestMutation: DenyRequestGQL,
    private toastr: ToastrService
  ) {}

  accept(): void {
    if (this.disable) return;
    this.disable = true;

    this.acceptRequestMutation
      .mutate({
        requestDetails: {
          communicationId: this.request.id,
          groupId: this.group.id,
          groupName: this.group.name,
          leaderId: this.currentUser.id,
          leaderFirstName: this.currentUser.nickName,
          requestorId: this.request.participant.id,
          participantId: this.request.participant.participantId,
          participantFirstName: this.request.participant.nickName,
          participantLastName: this.request.participant.lastName,
          participantEmail: this.request.participant.email
        },
      })
      .subscribe((response) => {
        const pendingRequests = this.group.pendingJoinRequests.filter(
          (pRequest) => pRequest.id != this.request.id
        );
        const newParticipants = [
          ...this.participants,
          response.data.acceptJoinRequest,
        ];

        var updatedGroup: GroupViewFragment = cloneDeep(this.group);
        updatedGroup.pendingJoinRequests = pendingRequests;
        currentGroupParticipants(newParticipants);
        currentGroup(updatedGroup);

        this.toastr.success('Accepted Join Request', 'Success');
        this.disable = false;
      });
  }

  deny(): void {
    if (this.disable) return;
    this.disable = true;

    this.denyRequestMutation
      .mutate({
        requestDetails: {
          communicationId: this.request.id,
          groupId: this.group.id,
          groupName: this.group.name,
          leaderId: this.currentUser.id,
          leaderFirstName: this.currentUser.nickName,
          requestorId: this.request.participant.id,
          participantId: this.request.participant.participantId,
          participantFirstName: this.request.participant.nickName,
          participantLastName: this.request.participant.lastName,
          participantEmail: this.request.participant.email,
        },
      })
      .subscribe((_) => {
        const pendingRequests = this.group.pendingJoinRequests.filter(
          (pRequest) => pRequest.id != this.request.id
        );

        var updatedGroup: GroupViewFragment = cloneDeep(this.group);
        updatedGroup.pendingJoinRequests = pendingRequests;

        currentGroup(updatedGroup);

        this.toastr.success('Deny Join Request', 'Success');
        this.disable = false;
      });
  }
}
