import { Component, Input, OnInit } from '@angular/core';
import { GroupViewFragment, SubgroupFragment } from 'src/generated/graphql';

@Component({
  selector: 'app-group-summary-subheader',
  templateUrl: './group-summary-subheader.component.html',
  styleUrls: ['./group-summary-subheader.component.scss']
})
export class GroupSummarySubheaderComponent implements OnInit {
  @Input() group: GroupViewFragment | SubgroupFragment;
  constructor() { }

  ngOnInit(): void {
  }

}
