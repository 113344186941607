import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import {
  ContactLeaderGQL,
  CurrentUserFragment,
  GetCurrentGroupGQL,
  GetCurrentUserGQL,
  GroupViewFragment,
} from 'src/generated/graphql';

@Component({
  selector: 'app-contact-leader',
  templateUrl: './contact-leader.component.html',
  styleUrls: ['./contact-leader.component.scss'],
})
export class ContactLeaderComponent implements OnInit {
  @Input() primary: boolean = true;
  showModal: boolean = false;
  submitted: boolean = false;
  showSignInModal: boolean = false;
  allowContactLeader: boolean = true;

  group: Observable<GroupViewFragment>;
  currentUser: Observable<CurrentUserFragment>;

  contactLeaderForm = this.formBuilder.group({
    subject: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
  });

  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private getCurrentGroup: GetCurrentGroupGQL,
    private getCurrentUser: GetCurrentUserGQL,
    private contactLeader: ContactLeaderGQL,
    private toastr: ToastrService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.group = this.getCurrentGroup
      .watch()
      .valueChanges.pipe(map((result) => result.data.currentGroup));

    this.currentUser = this.getCurrentUser
      .watch()
      .valueChanges.pipe(map((result) => result.data.currentUser));
  }

  open(currentUser: CurrentUserFragment): void {
    if (!currentUser) this.showSignInModal = true;
    else if (this.allowContactLeader) this.showModal = true;
  }

  onSignInSuccess = (): void => {
    this.showModal = true;
  };

  onSignInClose = (): void => {
    this.showSignInModal = false;
    this.cd.detectChanges();
  };

  close = () => {
    this.contactLeaderForm.reset();
    this.submitted = false;
    this.showModal = false;
    this.cd.detectChanges();
  };

  send = (group: GroupViewFragment) => {
    this.submitted = true;
    if (!this.contactLeaderForm.valid) return;
    if (!this.allowContactLeader) return;
    this.allowContactLeader = false;
    var formValues = this.contactLeaderForm.value;
    this.contactLeader
      .mutate({
        contactLeaderInput: {
          groupId: group.id,
          subject: formValues.subject,
          message: formValues.message,
        },
      })
      .subscribe(() => {
        this.close();
        this.toastr.success('Your message was successfully sent!', 'Success');
        this.analytics.trackContactLeader(group.type, group.id, group.leaders[0]?.id);
      });
  };
}
