import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AUTH_CONFIG } from './shared/auth.config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  AuthLibModule,
  httpInterceptorProviders,
} from '@crds_npm/crds-angular-auth-wrapper-lib';
import { GroupViewModule } from './features/group-view/group-view.module';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MetaService } from './shared/meta/meta.service';
import { APP_BASE_HREF } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { Angulartics2Module } from 'angulartics2';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    GroupViewModule,
    HttpClientModule,
    AuthLibModule.forRoot(AUTH_CONFIG),
    GraphQLModule,
    BrowserAnimationsModule, // required animations module for Toastr
    ToastrModule.forRoot({
      positionClass: 'toast-crds',
    }),
    SharedModule,
    Angulartics2Module.forRoot(),
  ],
  providers: [
    httpInterceptorProviders,
    MetaService,
    {
      provide: APP_BASE_HREF,
      useValue: window['base-href'],
    },
    {
      provide: '404resolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = `${environment.CRDS_NET_ENDPOINT}/findgroups`;
      },
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
