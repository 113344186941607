<crds-modal
  [isActive]="showModal"
  [onModalClose]="close"
>
  <crds-sign-in
    provider="{{ DEFAULT_AUTH_PROVIDER }}"
    needs-redirect=false
    [onSuccess]="handleSuccess"
  ></crds-sign-in
  >
</crds-modal>
