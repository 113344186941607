import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getUserBackgroundColor } from 'src/app/app.constants';
import {
  CurrentUserRoleGQL,
  GetCurrentGroupGQL,
  GroupLeaderFragment,
  GroupRole,
} from 'src/generated/graphql';

@Component({
  selector: 'app-group-leader',
  templateUrl: './group-leader.component.html',
  styleUrls: ['./group-leader.component.scss'],
})
export class GroupLeaderComponent implements OnInit {
  groupLeaders: Observable<(GroupLeaderFragment & { backgroundColor: string })[]>;
  currentUserRole: Observable<GroupRole>;

  constructor(
    private getCurrentGroup: GetCurrentGroupGQL,
    private currentUserRoleQuery: CurrentUserRoleGQL
  ) {}

  ngOnInit(): void {
    this.groupLeaders = this.getCurrentGroup.watch().valueChanges.pipe(
      map((result) => {
        return result.data.currentGroup?.leaders
        .map((p) => {
          return {
            ...p,
            backgroundColor: getUserBackgroundColor(parseInt(p.id))
          };
        })
      })
    );

    this.currentUserRole = this.currentUserRoleQuery
      .watch()
      .valueChanges.pipe(map((result) => result.data.currentUserRole));
  }
}

@Pipe({ name: 'firstLetter' })
export class FirstLetterPipe implements PipeTransform {
  transform(str: string) {
    return str.substring(0, 1) + '.';
  }
}
