import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  GetCurrentGroupGQL,
  GroupDescriptionFragment,
  IsCurrentGroupAdminGQL,
  CurrentUserRoleGQL,
  GroupRole,
  SetGroupPrivacyGQL,
  GroupViewFragment,
  EndDateGroupGQL,
  UpdateGroupGQL,
  UpdateGroupInput,
  GroupAttributeInput,
  GetFocusesDocument,
} from 'src/generated/graphql';
import { Constants } from '../../app.constants';
import { MetaService } from '../../shared/meta/meta.service';
import {
  currentGroup,
  groupSaveResponse,
  showPendingJoinRequestsModal,
} from '../../shared/state/state.resolver';
import { cloneDeep } from '@apollo/client/utilities';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-group-view',
  templateUrl: './group-view.component.html',
  styleUrls: ['./group-view.component.scss'],
})
export class GroupViewComponent implements OnInit {
  group: Observable<GroupViewFragment>;
  isCurrentGroupAdmin: Observable<boolean>;
  currentUserRole: Observable<GroupRole>;
  groupLeaderEditOptions: Observable<{ [key: string]: Function }>;
  isParent: Observable<boolean>;
  showPendingRequestsAlert: Observable<boolean>;
  displayRenewModal: boolean = false;
  displayEndGroupModal: boolean = false;

  constructor(
    private getCurrentGroupQuery: GetCurrentGroupGQL,
    private isCurrentGroupAdminQuery: IsCurrentGroupAdminGQL,
    private currentUserRoleQuery: CurrentUserRoleGQL,
    private setGroupPrivacyMutation: SetGroupPrivacyGQL,
    private endDateGroupMutation: EndDateGroupGQL,
    private updateGroupMutation: UpdateGroupGQL,
    private router: Router,
    private toastr: ToastrService,
    private meta: MetaService
  ) {}

  ngOnInit(): void {
    this.isCurrentGroupAdmin = this.isCurrentGroupAdminQuery
      .watch()
      .valueChanges.pipe(map((result) => result.data.isCurrentGroupAdmin));

    this.currentUserRole = this.currentUserRoleQuery
      .watch()
      .valueChanges.pipe(map((result) => result.data.currentUserRole));

    this.group = this.getCurrentGroupQuery.watch().valueChanges.pipe(
      tap((result) => {
        var group = result.data.currentGroup;
        this.meta.updateTitle(group.name);
        this.meta.updateOgUrl(location.href);
        this.meta.updateDescription(group.description);
        this.meta.updateImage(group.image);
      }),
      map((result) => result.data.currentGroup)
    );

    this.isParent = this.group.pipe(
      map(
        (group) =>
          !!group?.subGroups.filter(
            (g) => g.type?.id != Constants.WaitListGroupTypeId
          ).length
      )
    );

    this.groupLeaderEditOptions = this.group.pipe(
      map((group: GroupDescriptionFragment) => {
        return this.initGroupLeaderEditOptions(group);
      })
    );

    this.showPendingRequestsAlert = this.getCurrentGroupQuery
      .watch()
      .valueChanges.pipe(
        map((result) => {
          return (
            !!result.data.currentGroup.pendingJoinRequests &&
            result.data.currentGroup.pendingJoinRequests.length > 0
          );
        })
      );
  }

  initGroupLeaderEditOptions(group: GroupDescriptionFragment) {
    let button = group.availableOnline
      ? this.makePrivateButton(group)
      : this.makePublicButton(group);
    return button;
  }

  makePublicButton(group: GroupDescriptionFragment) {
    const button = this.makeButton(group);
    return {
      ...button,
      'Unhide group': () => this.changeGroupPrivacy(group),
    };
  }

  makePrivateButton(group: GroupDescriptionFragment) {
    const button = this.makeButton(group);
    return {
      ...button,
      'Hide group': () => this.changeGroupPrivacy(group),
    };
  }

  makeButton(group: GroupDescriptionFragment) {
    return {
      'Edit group': () => this.editGroup(group),
      'Renew group': () => (this.displayRenewModal = true),
      'End group': () => (this.displayEndGroupModal = true),
    };
  }

  /* Removed at Journey End 10/24/2022
  makeJourneyButton(group: GroupDescriptionFragment, button: any) {
    if (group.focuses.filter((x) => x.name === 'journey').length === 0) {
      return {
        ...button,
        'Add Journey Tag': () => this.addJourney(group),
      };
    }
    return {
      ...button,
      'Remove Journey Tag': () => this.removeJourney(group),
    };
  }
  */

  changeGroupPrivacy = (group: GroupDescriptionFragment) => {
    return this.setGroupPrivacyMutation
      .mutate({
        id: group.id,
        availableOnline: !group.availableOnline,
      })
      .subscribe((result) => {
        const { availableOnline } = result.data.setGroupPrivacy;

        var updatedGroup: GroupViewFragment = cloneDeep(group);
        updatedGroup.availableOnline = availableOnline;

        currentGroup(updatedGroup);

        if (availableOnline) {
          this.toastr.success('Your group is now public', 'Success!');
        } else {
          this.toastr.success('Your group is now hidden', 'Success!');
        }
      });
  };

  /* Removed at Journey End 10/24/2022
  removeJourney = (group: GroupDescriptionFragment) => {
    let focuses = group.focuses
      .filter((x) => x.name !== 'journey')
      .map((focus) => {
        return {
          id: focus.id,
          name: focus.name,
        };
      });
    this.updateGroupFocus(group, focuses);
  };

  addJourney = (group: GroupDescriptionFragment) => {
    let focuses = group.focuses.map((focus) => {
      return {
        id: focus.id,
        name: focus.name,
      };
    });
    focuses.push({
      id: group.crossroadsHosted
        ? Constants.Focuses.JourneyHosted
        : Constants.Focuses.JourneySmall,
      name: 'journey',
    });
    this.updateGroupFocus(group, focuses);
  };
  
  updateGroupFocus = (group: GroupDescriptionFragment, focuses: any) => {
    this.updateGroupMutation
      .mutate({
        id: group.id,
        updateGroupInput: <UpdateGroupInput>{ focuses: focuses },
      })
      .subscribe((result) => {
        if (result.data.updateGroup.status[0] === 'SUCCESS') {
          let updatedGroup: GroupViewFragment = cloneDeep(group);
          updatedGroup.focuses = focuses;
          updatedGroup.groupDetails.focus = focuses.map(
            (focus: any) => focus.name
          );
          currentGroup(updatedGroup);

          this.toastr.success(
            focuses.filter((focus: any) => focus.name === 'journey').length > 0
              ? 'Your group is now a journey group'
              : 'Your group is no longer a journey group',
            'Success!'
          );
        } else {
          this.toastr.error("Oops! Looks like something wasn't quite right...");
        }
      });
  };
  */

  closeModal = () => {
    this.displayRenewModal = false;
    this.displayEndGroupModal = false;
  };

  editGroup = (group: GroupDescriptionFragment) => {
    const baseHref = environment.CRDS_ENV === 'prod' ? 'www' : 'int';
    this.router.navigateByUrl(`/edit/${group.id}`);
  };

  renewGroup = (group: GroupDescriptionFragment) => {
    const endDate = Math.floor(
      new Date(environment.GROUPS_END_DATE).getTime() / 1000
    );
    return this.updateGroupEndDate(group.id, endDate, 'Group Has Been Renewed');
  };

  endGroup = (group: GroupDescriptionFragment) => {
    const endDate = Math.floor(Date.now() / 1000);
    return this.updateGroupEndDate(group.id, endDate, 'Group Has Ended');
  };

  updateGroupEndDate = (id: string, endDate: number, message: string) => {
    return this.endDateGroupMutation
      .mutate({
        id: id,
        endDate: endDate,
      })
      .subscribe((_) => {
        const redirect = this.displayEndGroupModal;
        this.closeModal();
        redirect && this.router.navigateByUrl(`/${id}`);
        this.toastr.success(message, 'Success!');
      });
  };

  openPendingRequestModal() {
    showPendingJoinRequestsModal(true);
  }
}

@Pipe({ name: 'site' })
export class SitePipe implements PipeTransform {
  transform(group: GroupDescriptionFragment): string {
    if (group.site.id === '15') return 'Online';
    return group.site.name;
  }
}
