<div
  *ngIf="{
    currentUser: currentUser | async,
    isSuperAdmin: isSuperAdmin | async,
    spouse: spouse | async,
    spouseIsRegistered: spouseIsRegistered | async,
    isPrimaryContact: isPrimaryContact | async,
    disabled: disabled
  } as data"
>
  <crds-button
    *ngIf="!!data.isSuperAdmin || !!group.currentParticipant?.groupRole || !!data.isPrimaryContact; else registerButton"
    color="orange"
    [text]="
      (group | groupAdmin: data.currentUser: data.isSuperAdmin)
        ? 'Manage my group'
        : 'View my group'
    "
    size="small"
    (click)="redirectToSmallGroup(group)"
  ></crds-button>
  <ng-template #registerButton>
    <div *ngIf="!data.currentUser && showSignInModal">
      <app-signin-modal
        [showModal]="showSignInModal"
        [onClose]="onSignInClose"
        [onSuccess]="onSignInSuccess"
      ></app-signin-modal>
    </div>

    <div *ngIf="waitlistGroup?.currentParticipant?.groupRole; else registerLink">
      You're on the waitlist!
    </div>
    <ng-template #registerLink>
      <a
        class="waitlist-link"
        (click)="open(data.currentUser)"
        *ngIf="group.isGroupFull && !group.registrationNotRequired"
      >
        {{ "Join the waitlist (" + waitlistGroup.participantCount + ")" }}
      </a>
      <a
        class="register-link"
        (click)="open(data.currentUser)"
        *ngIf="!group.isGroupFull && !group.registrationNotRequired"
        >Register now</a
      >
      <span *ngIf="group.registrationNotRequired"
        class="text-gray-ligth">
        <i>No registration required</i>
      </span>
    </ng-template>
    <div *ngIf="showModal">
      <crds-modal
        [label]="group.isGroupFull ? 'Join the waitlist' : 'Register'"
        [isActive]="showModal"
        [onModalClose]="close"
      >
        <p *ngIf="!group.isGroupFull" class="text-gray-lighter">
          Complete and confirm the following information to register for this
          group.
        </p>
        <p *ngIf="group.isGroupFull" class="text-gray-lighter">
          Complete and confirm the following information to sign up for this
          group's waitlist. If a spot opens up, a group leader will reach out to
          you via email.
        </p>
        <app-group-summary-subheader
          [group]="group"
        ></app-group-summary-subheader>
        <hr class="push-ends" />
        <div *ngIf="data.spouse === undefined; else checklist">
          <crds-loader [isActive]="data.spouse === undefined"></crds-loader>
        </div>
        <ng-template #checklist>
          <form [formGroup]="registerForm">
            <div class="form-group">
              <div class="row soft-half-sides">
                <div class="col soft-quarter-top soft-half-bottom">
                  <strong
                    >Select who you'd like to
                    {{ group.isGroupFull ? "join the waitlist" : "register" }}
                    for this group:</strong
                  >
                  <div *ngIf="!checklistLoading">
                    <label class="control checkbox">
                      <input
                        type="checkbox"
                        name="registerUser"
                        formControlName="registerUser"
                      />
                      {{ data.currentUser?.nickName | titlecase }}
                      {{ data.currentUser?.lastName | titlecase }} ({{
                        data.currentUser?.email | titlecase
                      }})
                      <span class="control-indicator" tabindex="0"></span>
                    </label>
                    <label
                      *ngIf="data.spouse"
                      class="control checkbox"
                      [ngClass]="{ disabled: data.spouseIsRegistered }"
                    >
                      <input
                        type="checkbox"
                        name="registerSpouse"
                        formControlName="registerSpouse"
                      />
                      {{ data.spouse?.nickName | titlecase }}
                      {{ data.spouse?.lastName | titlecase }} ({{
                        data.spouse?.email | titlecase
                      }})
                      <span class="control-indicator" tabindex="0"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="push-top push-quarter-bottom"
              style="display: 'flex'; justify-content: 'flex-start'"
            >
              <crds-button
                color="cyan"
                text="Submit"
                [disabled]="!registerForm.valid || data.disabled"
                style="margin-right: '8px'"
                (click)="
                  register(
                    group,
                    data.currentUser,
                    data.spouse,
                    data.spouseIsRegistered
                  )
                "
              ></crds-button>
              <crds-button
                color="gray-light"
                display="outline"
                text="Cancel"
                (click)="close()"
              ></crds-button>
            </div>
          </form>
        </ng-template>
      </crds-modal>
    </div>
  </ng-template>
</div>
