<div
  *ngIf="{
    group: group | async,
    isCurrentGroupAdmin: isCurrentGroupAdmin | async,
    currentUserRole: currentUserRole | async,
    groupLeaderEditOptions: groupLeaderEditOptions | async,
    isParent: isParent | async,
    showPendingRequestsAlert: showPendingRequestsAlert | async
  } as data"
>
  <div
    *ngIf="
      data.group &&
      data.isCurrentGroupAdmin !== undefined &&
      data.groupLeaderEditOptions
    "
  >
    <crds-modal
      [label]="displayRenewModal ? 'Renew Group' : 'End Group'"
      [isActive]="displayRenewModal || displayEndGroupModal"
      [appendToBody]="false"
    >
      <div
        class="flush-top text-gray-light soft-ends"
        *ngIf="displayRenewModal; then renewGroupText; else endGroupText"
      ></div>
      <ng-template #renewGroupText>
        Are you sure you want to renew your group?
        <br />
      </ng-template>
      <ng-template #endGroupText>
        Are you sure you want to end your group?
        <br />
      </ng-template>

      <br />

      <div
        class="push-top push-quarter-bottom"
        style="display: 'flex'; justify-content: 'flex-start'"
        *ngIf="displayRenewModal; then renewGroupButton; else endGroupButton"
      ></div>
      <ng-template #renewGroupButton>
        <crds-button
          color="cyan"
          text="Renew group"
          style="margin-right: '8px'"
          (click)="renewGroup(data.group)"
        ></crds-button>
        <crds-button
          color="gray-light"
          display="outline"
          text="Cancel"
          (click)="closeModal()"
        ></crds-button>
      </ng-template>
      <ng-template #endGroupButton>
        <crds-button
          color="cyan"
          text="End group"
          style="margin-right: '8px'"
          (click)="endGroup(data.group)"
        ></crds-button>
        <crds-button
          color="gray-light"
          display="outline"
          text="Cancel"
          (click)="closeModal()"
        ></crds-button>
      </ng-template>
    </crds-modal>

    <app-group-leader-action-required
      *ngIf="data.isCurrentGroupAdmin"
    ></app-group-leader-action-required>
    <app-group-save-details></app-group-save-details>
    <div class="container page">
      <div class="row">
        <!-- Group Description -->
        <section class="col-xs-12 col-md-7">
          <div class="push-bottom">
            <div class="section-header flush-top text-blue group-title">
              {{ data.group.name }}
            </div>
            <h5
              *ngIf="data.group.crossroadsHosted && data.group.isMeeting"
              class="group-name-subtitle text-orange-dark push-half-ends font-family-base-bold text-uppercase"
            >
              {{ data.group.groupDetails.time }} Meeting (Crossroads
              {{ data.group | site }})
            </h5>
            <div
              *ngIf="!data.group.availableOnline"
              class="text-gray-dark font-family-accent tag"
            >
              This group is hidden from the public.
            </div>
            <div class="meta-info push-half-bottom">
              <div class="meta-tags__container">
                <div
                  class="meta-tags"
                  [ngClass]="{
                    'is-leader': data.isCurrentGroupAdmin && !data.isParent
                  }"
                  *ngFor="let focus of data.group.groupDetails?.focus"
                >
                  <span class="meta-tags__tag">
                    {{ focus }}
                  </span>
                </div>
              </div>

              <div class="action-btn_container">
                <div class="leader-edit-dropdown__wrapper">
                  <crds-dropdown
                    *ngIf="data.isCurrentGroupAdmin && !data.isParent"
                    text="Edit"
                    class="leader-edit-dropdown"
                    [options]="data.groupLeaderEditOptions"
                  ></crds-dropdown>
                </div>
                <crds-button
                  class="pending-request-btn"
                  *ngIf="
                    data.isCurrentGroupAdmin && data.showPendingRequestsAlert
                  "
                  color="orange-dark"
                  display="solid"
                  size="sm"
                  icon="bell-fill"
                  icon-size="14"
                  icon-color="white"
                  icon-align="left"
                  text="View Pending Requests"
                  (click)="openPendingRequestModal()"
                ></crds-button>
              </div>
            </div>
            <div [innerHTML]="data.group.description"></div>
            <div class="push-top">
              <crds-social-share-icons
                pageTitle="Check out this group - {{ data.group.name }}!"
                color="blue"
                size="26"
              ></crds-social-share-icons>
            </div>
          </div>
          <div *ngIf="!data.isParent">
            <app-group-leader></app-group-leader>
          </div>
        </section>
        <aside class="col-xs-12 col-md-4 col-md-offset-1 push-half-bottom">
          <app-group-details></app-group-details>
        </aside>
      </div>

      <div
        *ngIf="
          !data.isParent && !data.currentUserRole && !data.isCurrentGroupAdmin
        "
        class="button-group"
      >
        <app-group-try-this-group class="push-quarter-right">
        </app-group-try-this-group>
        <app-contact-leader [primary]="false"></app-contact-leader>
      </div>
      <div
        class="button-group"
        *ngIf="data.currentUserRole && !data.isCurrentGroupAdmin"
      >
        <app-contact-leader
          class="push-quarter-right"
          [primary]="true"
        ></app-contact-leader>
        <app-leave-group></app-leave-group>
      </div>

      <div>
        <h3
          *ngIf="data.isParent"
          class="text-uppercase header font-family-condensed-extra"
        >
          Join A Group
        </h3>
        <hr />
        <div class="row soft-half-top">
          <div
            *ngIf="!data.isParent; then participants; else meetingSites"
          ></div>

          <ng-template #meetingSites>
            <div class="col-xs-12">
              <app-group-meeting-sites></app-group-meeting-sites>
            </div>
          </ng-template>

          <ng-template #participants>
            <div class="col-xs-12 col-md-7">
              <div class="row" *ngIf="isCurrentGroupAdmin | async">
                <app-group-participants></app-group-participants>
              </div>
            </div>
          </ng-template>

          <aside
            class="col-xs-12 col-md-4 col-md-offset-1 push-half-bottom"
            *ngIf="!!data.isCurrentGroupAdmin && !data.isParent"
          >
            <div class="group-details__container soft">
              <h4 class="text-uppercase push-half-bottom">Group resources</h4>
              <ul
                class="list-unstyled font-family-serif font-size-small dash-list flush-bottom underline"
              >
                <crds-content-block
                  id="group-leader-resource-list"
                ></crds-content-block>
              </ul>
            </div>
          </aside>
        </div>
      </div>
    </div>
  </div>
</div>
