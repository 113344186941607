<div style="margin-bottom: -22px;" *ngIf="{ group: group | async, currentUser: currentUser | async } as data">
  <div *ngIf="type == 'link'; else leaveButton">
    <a
    [ngClass]="{ 'leader': isLeader === true }"
    (click)="open()">Leave group</a>
  </div>
  <ng-template #leaveButton>
    <crds-button
      (click)="open()"
      text="Leave this group"
      display="outline"
      color="gray"
    ></crds-button>
  </ng-template>
  <crds-modal
    label="Leave this group"
    [isActive]="showModal"
    [onModalClose]="close"
  >
    <div class="push-bottom push-top">
      Are you sure you want to remove yourself from this group?
    </div>
    <crds-button
      color="cyan"
      text="Yes"
      style="margin-right: '8px'"
      (click)="leave(data.currentUser, data.group)"
    ></crds-button>
    <crds-button
      color="gray-light"
      display="outline"
      text="Cancel"
      (click)="close()"
    ></crds-button>
  </crds-modal>
</div>
