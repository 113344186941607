import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  CancelInvitationGQL,
  GetCurrentGroupGQL,
  GroupViewFragment,
  PendingInvitationFragment,
} from 'src/generated/graphql';
import { currentGroup } from 'src/app/shared/state/state.resolver';
import { cloneDeep } from '@apollo/client/utilities';

@Component({
  selector: 'app-group-invited-participants',
  templateUrl: './group-invited-participants.component.html',
  styleUrls: ['./group-invited-participants.component.scss'],
})
export class GroupInvitedParticipantsComponent implements OnInit {
  pendingInvitations: Observable<PendingInvitationFragment[]>;

  constructor(
    private getCurrentGroupQuery: GetCurrentGroupGQL,
    private cancelInvitation: CancelInvitationGQL,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.pendingInvitations = this.getCurrentGroupQuery
      .watch()
      .valueChanges.pipe(
        map((result) => result.data.currentGroup.pendingInvitations)
      );
  }

  cancelInvite(
    invitee: PendingInvitationFragment,
    pendingInvitations: PendingInvitationFragment[]
  ): void {
    this.cancelInvitation
      .mutate({
        invitationGUID: invitee.invitationGUID,
      })
      .subscribe((result) => {
        const pendingInvites = pendingInvitations.filter(
          (i) => i.invitationGUID !== invitee.invitationGUID
        );

        var updatedGroup: GroupViewFragment = cloneDeep(currentGroup());
        updatedGroup.pendingInvitations = pendingInvites;

        currentGroup(updatedGroup);
        this.toastr.success('Cancelled Invitation', 'Success');
      });
  }
}
