import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { Constants } from 'src/app/app.constants';
import { currentGroup } from 'src/app/shared/state/state.resolver';
import {
  GetCurrentUserGQL,
  CurrentUserFragment,
  SubgroupFragment,
  UserSpouseGQL,
  SpouseFragment,
  RegisterForGroupGQL,
  WaitingListFragment,
  GroupViewFragment,
  IsSuperAdminGQL,
} from 'src/generated/graphql';
import { cloneDeep } from '@apollo/client/utilities';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';

@Component({
  selector: 'app-group-register',
  templateUrl: './group-register.component.html',
  styleUrls: ['./group-register.component.scss'],
})
export class GroupRegisterComponent implements OnInit {
  @Input() group: SubgroupFragment;
  isSuperAdmin: Observable<boolean>;
  currentUser: Observable<CurrentUserFragment>;
  spouse: Observable<SpouseFragment>;
  spouseIsRegistered: Observable<boolean>;
  showModal: boolean = false;
  disabled: boolean = false;
  showSignInModal: boolean = false;
  checklistLoading: boolean = false;
  registerForm: FormGroup;
  waitlistGroup: WaitingListFragment;
  LeaderRoleId: number = Constants.LeaderRoleId;
  isPrimaryContact: Observable<boolean>;

  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private getCurrentUser: GetCurrentUserGQL,
    private toastr: ToastrService,
    private getUsersSpouse: UserSpouseGQL,
    private registerForGroup: RegisterForGroupGQL,
    private getIsSuperAdmin: IsSuperAdminGQL,
    private router: Router,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.checkWaitlistGroup();

    this.currentUser = this.getCurrentUser
      .watch()
      .valueChanges.pipe(map((result) => result.data.currentUser));

    this.isPrimaryContact = this.getCurrentUser.watch().valueChanges.pipe(
      map((result) => {
        return result.data.currentUser?.id === this.group.primaryContact.id;
      })
    );

    this.isSuperAdmin = this.getIsSuperAdmin.watch().valueChanges.pipe(
      map((result) => {
        return result.data.isSuperAdmin;
      })
    );

    this.spouse = this.currentUser.pipe(
      distinctUntilChanged((a, b) => a?.id == b?.id),
      tap(() => (this.checklistLoading = true)),
      switchMap((currentUser) => {
        if (!currentUser) return of(null);
        return this.getUsersSpouse.watch(null).valueChanges.pipe(
          map((spouseResult) => {
            this.checklistLoading = false;
            return spouseResult.data.user?.spouse;
          })
        );
      })
    );

    this.spouseIsRegistered = this.spouse.pipe(
      map((spouse) => {
        if (!spouse) {
          this.registerForm = this.formBuilder.group(
            {
              registerUser: new FormControl(),
            },
            { validators: this.validate() }
          );
          return false;
        }

        const isRegistered = !!spouse.groups.find((g) => {
          if (this.group.isGroupFull) return g.id == this.waitlistGroup.id;
          return g.id === this.group.id;
        });
        this.registerForm = this.formBuilder.group(
          {
            registerUser: new FormControl(),

            registerSpouse: new FormControl({
              disabled: isRegistered,
              value: isRegistered,
            }),
          },
          { validators: this.validate() }
        );

        this.cd.detectChanges();
        return isRegistered;
      })
    );
  }

  ngOnChange() {
    this.checkWaitlistGroup();
  }

  private checkWaitlistGroup() {
    this.waitlistGroup = {
      ...this.group.subGroups.find(
        (g) => g.type?.id == Constants.WaitListGroupTypeId
      ),
    };
  }

  redirectToSmallGroup(group: SubgroupFragment) {
    this.router.navigate([`/details/${group.id}`]);
  }

  validate() {
    return (group: FormGroup) => {
      var atLeastOneSelected: boolean = false;

      Object.keys(group.controls).forEach((key) => {
        const control = group.controls[key];
        if (control.value && !control.disabled) atLeastOneSelected = true;
      });

      if (atLeastOneSelected) group.controls['registerUser'].setErrors(null);
      else
        group.controls['registerUser'].setErrors({
          incorrect: true,
        });
    };
  }

  open(currentUser: CurrentUserFragment): void {
    if (this.group.customRegistrationLink) {
      window.open(this.group.customRegistrationLink, '_blank');
      return;
    }

    if (!currentUser) {
      this.showSignInModal = true;
      return;
    }

    this.showModal = true;
  }

  onSignInSuccess = (): void => {
    this.showModal = true;
  };

  onSignInClose = (): void => {
    this.showSignInModal = false;
    this.cd.detectChanges();
  };

  close = () => {
    this.disabled = false;
    this.showModal = false;
    this.cd.detectChanges();
  };

  register = (
    group: SubgroupFragment,
    user: CurrentUserFragment,
    spouse: SpouseFragment,
    spouseIsRegistered: boolean
  ) => {
    if (!this.registerForm.valid) return;
    if (this.disabled) return;
    this.disabled = true;
    var formValues = this.registerForm.value;

    var registrationInput = [];
    if (formValues.registerUser)
      registrationInput.push({
        contactId: user.id,
        nickName: user.nickName,
        lastName: user.lastName,
        email: user.email,
        participantId: user.participantId,
        groupId: group.isGroupFull ? this.waitlistGroup.id : group.id,
        isWaitlist: group.isGroupFull,
        groupName: group.name,
        siteName: group.site.name,
        roleId: 16,
      });

    if (formValues.registerSpouse && !spouseIsRegistered)
      registrationInput.push({
        contactId: spouse.id,
        nickName: spouse.nickName,
        lastName: user.lastName,
        email: user.email,
        participantId: spouse.participantId,
        groupId: group.isGroupFull ? this.waitlistGroup.id : group.id,
        isWaitlist: group.isGroupFull,
        groupName: group.name,
        siteName: group.site.name,
        roleId: 16,
      });

    this.registerForGroup
      .mutate({
        registrationInput: registrationInput,
      })
      .subscribe(() => {
        let currentGroupState: GroupViewFragment = cloneDeep(currentGroup());
        currentGroupState.subGroups = currentGroupState.subGroups.filter(
          (g) => g.id !== group.id
        );
        let newGroup = cloneDeep(group);

        if (formValues.registerUser) {
          if (group.isGroupFull) {
            this.waitlistGroup.participantCount += 1;
            this.waitlistGroup.currentParticipant = {
              ...this.waitlistGroup.currentParticipant,
              groupRole: {
                id: 16,
              },
            };
          } else {
            newGroup.currentParticipant = {
              groupRole: {
                name: 'Member',
                id: 16,
              },
            };
          }
        }

        if (formValues?.registerSpouse) {
          if (group.isGroupFull) this.waitlistGroup.participantCount += 1;
          this.spouseIsRegistered.pipe(take(1)).subscribe();
        }

        newGroup.subGroups = [this.waitlistGroup];
        currentGroupState.subGroups.push(newGroup);
        currentGroupState.subGroups = currentGroupState.subGroups.sort(
          (a, b) => Number(a.id) - Number(b.id)
        );
        currentGroup(currentGroupState);

        this.close();
        this.toastr.success(
          'Your registration has been successfully submitted!',
          'Success'
        );
        this.analytics.trackGroupRegister(group.type, group.id);
      });
  };
}

@Pipe({ name: 'groupAdmin' })
export class GroupAdminPipe implements PipeTransform {
  transform(
    group: SubgroupFragment,
    user: CurrentUserFragment,
    isSuperAdmin: boolean
  ): boolean {
    return (
      isSuperAdmin ||
      group?.currentParticipant?.groupRole?.id === Constants.LeaderRoleId ||
      group?.primaryContact?.id === user?.id
    );
  }
}
