<div *ngIf="{ group: group | async, currentUser: currentUser | async } as data">
  <div *ngIf="!!data.group && !!data.currentUser">
    <crds-modal label="Add A New Participant" [isActive]="showModal" [onModalClose]="close">
      <div class="soft-half-top text-gray-light" style="margin-bottom: '3em'">
        Add participant information and they will be added to your group.
      </div>

      <form [formGroup]="inviteParticipantForm">
        <div class="form-group" >
            <div class="row">
              <div class="col-sm-6">
                <input type="text" placeholder="First name" formControlName="firstName" class="form-control" />
              </div>
              <div class="col-sm-6 mobile-soft-half-top">
                <input type="text" placeholder="Last name" formControlName="lastName" class="form-control" />
              </div>
            </div>
            <div class="row soft-half-top">
              <div class="col-sm-12">
                <input type="email" placeholder="Email" formControlName="email" class="form-control" />
              </div>
            </div>
          </div>

        <div *ngIf="
            submitted &&
            inviteParticipantForm.controls['firstName'].invalid &&
            inviteParticipantForm.controls['firstName'].errors.required
          " class="error help-block">
          First Name Required
        </div>

        <div *ngIf="
            submitted &&
            inviteParticipantForm.controls['lastName'].invalid &&
            inviteParticipantForm.controls['lastName'].errors.required
          " class="error help-block">
          Last Name Required
        </div>

        <div *ngIf="
            submitted &&
            inviteParticipantForm.controls['email'].invalid &&
            inviteParticipantForm.controls['email'].errors.required
          " class="error help-block">
          Email Required
        </div>

        <div *ngIf="
            submitted &&
            inviteParticipantForm.controls['email'].invalid &&
            inviteParticipantForm.controls['email'].errors.email
          " class="error help-block">
          Invalid Email
        </div>

        <div class="push-top push-quarter-bottom" style="display: 'flex'; justify-content: 'flex-start'">
          <crds-button color="cyan" text="Add participant" style="margin-right: '8px'"
            (click)="save(data.group, data.currentUser)"></crds-button>
          <crds-button color="gray-light" display="outline" text="Cancel" [onClick]="close"></crds-button>
        </div>
      </form>
    </crds-modal>

    <div>
      <li>
        <div class="participant-card push-half-bottom new-participant" (click)="open()">
          <div class="icon-container">
            <crds-icon color="blue" name="plus" size="30" class="hydrated"></crds-icon>
          </div>
          <div class="text-gray-light font-family-accent text-title">
            Add a new participant
          </div>
        </div>
      </li>
    </div>
    <app-group-invited-participants></app-group-invited-participants>
  </div>
</div>
