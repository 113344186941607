import { Group, PendingGroupFragment } from 'src/generated/graphql';

export const Constants = {
  OnsiteParentGroup: '178218',
  LeaderRoleId: 22,
  WaitListGroupTypeId: '20',
  CrossroadsHostedSecurityRole: '1081',
  SuperAdminRole: '1097',
  ApprovedGroupLeaderStatusId: '4',
  AgeGroupsThatNeedApproval: [7089, 7090],
  HostedGroupTypeId: '8',
  MinistryType: {
    Community: '8',
    Healing: '39',
  },
  Focuses: {
    Healing: '22540',
    JourneySmall: '22553',
    JourneyHosted: '22552',
  },
  JourneyFocus: [{ id: '22553', name: 'journey' }],
};

export const DefaultGroupFlowState = {
  groupType: null,
  currentWizardStep: 0,
  maxWizardStep: 0,
  successfullySaved: false,
  backButtonHitAndShouldReresh: false,
  isEdit: false,
};

const brandColor: string[] = [
  '#253746',
  '#CDA077',
  '#4A4948',
  '#8A8D4A',
  '#BB4900',
];

export function getUserBackgroundColor(id: number): string {
  if (!id) return brandColor[0];
  return brandColor[id % 5];
}

export const DefaultPendingGroup: PendingGroupFragment = {
  id: null,
  type: null,
  site: null,
  name: null,
  description: null,
  ministry: {
    id: Constants.MinistryType.Community,
  },
  demographics: [],
  ageGroups: [
    {
      id: '7092',
      name: '20s',
    },
    {
      id: '7093',
      name: '30s',
    },
    {
      id: '7094',
      name: '40s',
    },
    {
      id: '7095',
      name: '50s',
    },
  ],
  focuses: [],
  kidsWelcome: null,
  startDate: Math.floor(new Date().getTime() / 1000),
  duration: null,
  ongoing: null,
  meetsOnline: null,
  offsiteMeetingAddress: null,
  capacity: -1,
  parentGroup: {
    id: null,
    name: null,
    description: null,
    ministry: {
      id: null,
    },
  },
  meetingSchedule: {
    time: null,
    day: null,
    frequency: null,
    endTime: null,
  },
  availableOnline: true,
  primaryContact: null,
  childcareAvailable: null,
  additionalServices: null,
  isLeader: null,
  isPrimaryContact: null,
  staffContact: null,
  leaderEmails: [],
  leaders: [],
  currentParticipant: null,
  customRegistrationLink: null,
  registrationNotRequired: null,
  __typename: 'Group',
};
