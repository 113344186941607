<a
[ngClass]="{ 'leader': isLeader === true }"
(click)="openModal()">Remove</a>
<crds-modal label="Remove Participant" [isActive]="showModal" [onModalClose]="closeX">
    <div class="flush-top text-gray-light" style="margin-bottom: 2em" >
        Reason for removing {{ userName }}:
      </div>
      <textarea #removalReason rows=5 cols=60 placeholder="These notes will be stored in a file but will NOT be shared with the participant."></textarea>
      <div class="push-top push-quarter-bottom" style="display: flex">
        <crds-button
          color="cyan"
          text="Save"
          style="margin-right: 8px"
          (click)="submitRemoveParticipant(removalReason.value)"></crds-button>
        <crds-button
          color="gray-light"
          display="outline"
          text="Cancel"
          (click) = "closeModal()"
        ></crds-button>
      </div>
</crds-modal>
