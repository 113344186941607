<section class="col-xs-12 col-md-12">
  <div class="participants-header push-bottom">
    <h2 class="component-header">Participants</h2>
    <crds-dropdown
      text="Contact participants"
      [options]="contactOptions"
    ></crds-dropdown>
  </div>

  <div>
    <ul class="list-unstyled">
      <div *ngIf="isCurrentGroupAdmin | async as isCurrentGroupAdmin">
        <app-group-invite-participant></app-group-invite-participant>
      </div>
      <div *ngIf="participants | async as participants">
        <div *ngFor="let participant of participants">
          <li>
            <div
              class="participant-card push-half-bottom"
              [ngClass]="{ leader: participant.groupRole.name == 'Leader' }"
            >
              <div class="user-avatar push-quarter-ends">
                <ng-template
                  [ngIf]="
                    participant.imageUrl !=
                    'https://crossroads-media.imgix.net/images/avatar.svg'
                  "
                  [ngIfElse]="noAvatarImg"
                >
                  <div
                    class="user-avatar__thumb"
                    [ngStyle]="{
                      'background-image': 'url(' + participant.imageUrl + ')'
                    }"
                  ></div>
                </ng-template>

                <ng-template #noAvatarImg>
                  <div
                    class="user-avatar__thumb"
                    [style.background-color]="participant.backgroundColor"
                  ></div>
                  <div class="user-avatar__no-img"></div>
                  <div class="user-avatar__initials">
                    {{ participant.nickName | slice: 0:1
                    }}{{ participant.lastName | slice: 0:1 }}
                  </div>
                </ng-template>
              </div>
              <div class="participant-card__info">
                <p class="push-quarter-ends">
                  <span
                    class="participant-card__label font-family-serif"
                    *ngIf="participant.groupRole.name == 'Leader'"
                    >LEADER:
                  </span>
                  {{ participant.nickName }} {{ participant.lastName }}
                  <br />
                  <span
                    class="participant-email font-family-serif font-size-small"
                    >{{ participant.email }}</span
                  >
                  <br />
                  <span
                    class="participant-email font-family-serif font-size-small"
                    >member since: {{ participant.startDate | dateFormat }}</span
                  >
                </p>
                <div
                  *ngIf="isCurrentGroupAdmin | async as isCurrentGroupAdmin"
                  class="actions__container"
                >
                  <div class="edit-container push-quarter-ends">
                    <app-group-edit-member-role
                      [groupParticipantId]="participant.groupParticipantId"
                      [isLeader]="participant.groupRole.name == 'Leader'"
                      [userName]="
                        participant.nickName + ' ' + participant.lastName
                      "
                      [leaderApprovalStatus]="participant.leaderApprovalStatus"
                      [groupRoleId]="participant.groupRole.id"
                    >
                    </app-group-edit-member-role>
                  </div>

                  <div *ngIf="currentUser | async as currentUser">
                    <div
                      *ngIf="
                        currentUser.participantId !== participant.participantId
                      "
                    >
                      <app-group-remove-participant-modal
                        [isLeader]="participant.groupRole.name == 'Leader'"
                        [groupParticipantId]="participant.groupParticipantId"
                        [userName]="
                          participant.nickName + ' ' + participant.lastName
                        "
                      ></app-group-remove-participant-modal>
                    </div>

                    <div
                      *ngIf="
                        currentUser.participantId === participant.participantId
                      "
                    >
                      <div class="edit-container push-quarter-ends">
                        <app-leave-group
                          [isLeader]="participant.groupRole.name == 'Leader'"
                          type="link"
                        >
                        </app-leave-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </div>
      </div>
    </ul>
  </div>
</section>
