import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GetCurrentGroupGQL,
  GroupDetailsFragment,
} from 'src/generated/graphql';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss'],
})
export class GroupDetailsComponent implements OnInit {
  groupDetails: Observable<GroupDetailsFragment>;
  public crossroadsHosted: boolean;

  constructor(private getCurrentGroup: GetCurrentGroupGQL) {}

  ngOnInit(): void {
    this.groupDetails = this.getCurrentGroup.watch().valueChanges.pipe(
      map((result) => {
        this.crossroadsHosted = result.data.currentGroup?.crossroadsHosted;
        return result.data.currentGroup?.groupDetails;
      })
    );
  }
}

@Pipe({ name: 'demographic' })
export class DemographicFormatPipe implements PipeTransform {
  transform(demographic: string): string {
    return demographic.replace(' only', '');
  }
}
