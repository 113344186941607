import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {
  FirstLetterPipe,
  GroupLeaderComponent,
} from './group-leader/group-leader.component';
import { GroupMeetingSitesComponent } from './group-meeting-sites/group-meeting-sites.component';
import {
  GroupMeetingSiteComponent,
  FullDayMeetingTimePipe,
  SiteNamePipe,
  StartDatePipe,
  WhoPipe,
} from './group-meeting-sites/group-meeting-site/group-meeting-site.component';
import {
  DateFormatPipe,
  GroupParticipantsComponent,
} from './group-participants/group-participants.component';
import { GroupRemoveParticipantModalComponent } from './group-remove-participant-modal/group-remove-participant-modal.component';
import { GroupInvitedParticipantsComponent } from './group-invite-participant/group-invited-participants/group-invited-participants.component';
import { GroupInviteParticipantComponent } from './group-invite-participant/group-invite-participant.component';
import { GroupTryThisGroupComponent } from './group-try-this-group/group-try-this-group.component';
import { ClipboardModule } from 'ngx-clipboard';
import { GroupLeaderActionRequiredComponent } from './group-leader-action-required/group-leader-action-required.component';
import {
  DemographicFormatPipe,
  GroupDetailsComponent,
} from './group-details/group-details.component';
import { ContactLeaderComponent } from './contact-leader/contact-leader.component';
import {
  GroupAdminPipe,
  GroupRegisterComponent,
} from './group-meeting-sites/group-meeting-site/group-register/group-register.component';
import { GroupSummarySubheaderComponent } from './group-summary-subheader/group-summary-subheader.component';
import { LeaveGroupComponent } from './group-participants/leave-group/leave-group.component';
import { GroupEditMemberRoleComponent } from './group-edit-member-role/group-edit-member-role.component';
import { GroupViewComponent, SitePipe } from './group-view.component';
import {
  GroupHasStatusPipe,
  GroupSaveDetailsComponent,
  SaveResponseTitlePipe,
} from './group-save-details/group-save-details.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CrdsContentBlockModule } from '@crds_npm/crds-content-block';
import { environment } from 'src/environments/environment';
import { AcceptDenyRequestComponent } from './group-leader-action-required/accept-deny-request/accept-deny-request.component';

@NgModule({
  declarations: [
    GroupViewComponent,
    GroupLeaderComponent,
    GroupMeetingSitesComponent,
    GroupMeetingSiteComponent,
    GroupParticipantsComponent,
    GroupRemoveParticipantModalComponent,
    GroupInviteParticipantComponent,
    GroupInvitedParticipantsComponent,
    GroupTryThisGroupComponent,
    FirstLetterPipe,
    DemographicFormatPipe,
    FullDayMeetingTimePipe,
    SiteNamePipe,
    SitePipe,
    StartDatePipe,
    DateFormatPipe,
    WhoPipe,
    GroupAdminPipe,
    GroupDetailsComponent,
    GroupLeaderActionRequiredComponent,
    ContactLeaderComponent,
    GroupRegisterComponent,
    GroupSummarySubheaderComponent,
    LeaveGroupComponent,
    GroupEditMemberRoleComponent,
    GroupSaveDetailsComponent,
    SaveResponseTitlePipe,
    GroupHasStatusPipe,
    AcceptDenyRequestComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    CrdsContentBlockModule.forRoot({
      endpoint: environment.CRDS_GQL_ENDPOINT,
      categories: Array('group tool'), // optional
      options: {}, // optional (not currently used)
    }),
  ],
  bootstrap: [GroupViewComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GroupViewModule {}
