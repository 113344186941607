<div
  class="group-details__container"
  *ngIf="groupDetails | async as groupDetails"
>
  <!-- Title -->
  <h4 class="text-uppercase push-half-bottom">Group Details</h4>

  <!-- Attr List -->
  <ul
    class="list-unstyled font-family-serif font-size-small dash-list flush-bottom"
  >
    <li>
      <span>
        <svg
          id="user"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 427.16 512"
        >
          <g id="Layer_2">
            <g id="Layer_1-2">
              <path
                d="M210.35,246.63c33.88,0,63.22-12.15,87.2-36.13s36.12-53.3,36.12-87.19S321.52,60.1,297.54,36.12,244.23,0,210.35,0s-63.22,12.15-87.19,36.12S87,89.43,87,123.31s12.16,63.23,36.13,87.2S176.48,246.63,210.35,246.63Z"
              />
              <path
                d="M426.13,393.7A305.91,305.91,0,0,0,422,361.35a254.36,254.36,0,0,0-8-32.53,160.8,160.8,0,0,0-13.37-30.33,114.52,114.52,0,0,0-20.16-26.28,89,89,0,0,0-29-18.2,100,100,0,0,0-37-6.69c-5.23,0-10.28,2.14-20.05,8.5-6,3.92-13,8.45-20.88,13.46-6.7,4.27-15.79,8.28-27,11.9a104.91,104.91,0,0,1-66.09,0c-11.21-3.62-20.29-7.62-27-11.89-7.77-5-14.8-9.5-20.9-13.47-9.75-6.36-14.81-8.5-20-8.5a99.77,99.77,0,0,0-37,6.7,88.56,88.56,0,0,0-29,18.19,114.56,114.56,0,0,0-20,26.28,161.58,161.58,0,0,0-13.38,30.34,257.84,257.84,0,0,0-7.95,32.52A303.72,303.72,0,0,0,1,393.71c-.68,9.8-1,20-1,30.24,0,26.73,8.5,48.36,25.25,64.32C41.8,504,63.69,512,90.32,512H336.85c26.62,0,48.51-8,65.06-23.73,16.76-15.95,25.25-37.59,25.25-64.32C427.16,413.63,426.81,403.45,426.13,393.7Z"
              />
            </g>
          </g>
        </svg>
      </span>
      <span *ngIf="!!groupDetails.ageRange">
        {{ groupDetails.demographic | demographic }},
        {{ groupDetails.ageRange }}
      </span>
      <span *ngIf="!groupDetails.ageRange">
        {{ groupDetails.demographic | demographic }}
      </span>
    </li>
    <li>
      <crds-icon
        name="clock-o"
        size="16"
        color="fawn"
        class="hydrated"
      ></crds-icon>
      <span *ngIf="!!groupDetails.frequency">
        {{ groupDetails.time }}, {{ groupDetails.frequency }}
      </span>
      <span *ngIf="!groupDetails.frequency">
        {{ groupDetails.time }}
      </span>
      <span> </span>
    </li>
    <li *ngIf="!!groupDetails.location">
      <crds-icon
        name="map-marker"
        size="17"
        color="orange-dark"
        class="hydrated"
      ></crds-icon>
      <span>
        {{ groupDetails.location }}
      </span>
    </li>
    <li *ngIf="groupDetails.inPerson">
      <crds-icon
        name="check-circle"
        size="16"
        color="green-secondary"
        class="hydrated"
      ></crds-icon>
      <span>In-person</span>
    </li>
    <li *ngIf="groupDetails.virtual">
      <crds-icon
        name="check-circle"
        size="16"
        color="olive"
        class="hydrated"
      ></crds-icon>
      <span>Virtual</span>
    </li>
    <li *ngIf="crossroadsHosted">
      <crds-icon
        [name]="groupDetails.childcareAvailable ? 'heart' : 'close-circle'"
        size="16"
        [color]="
          groupDetails.childcareAvailable
            ? 'orange-dark'
            : 'gray-light-disabled'
        "
        class="hydrated"
      ></crds-icon>
      <div
        *ngIf="
          groupDetails.childcareAvailable === groupDetails.childcareUnavailable;
          then someChildcare;
          else regularChildcare
        "
      ></div>
      <ng-template #someChildcare>
        <span>Childcare for some locations</span>
      </ng-template>
      <ng-template #regularChildcare>
        <span [ngClass]="{ 'not-available': !groupDetails.childcareAvailable }">
          Childcare available
        </span>
      </ng-template>
    </li>
    <li>
      <crds-icon
        [name]="groupDetails.kidsWelcome ? 'heart' : 'close-circle'"
        size="16"
        [color]="
          groupDetails.kidsWelcome ? 'orange-dark' : 'gray-light-disabled'
        "
        class="hydrated"
      ></crds-icon>
      <div
        *ngIf="
          groupDetails.kidsWelcome === groupDetails.kidsUnwelcome;
          then someKidsWelcome;
          else regularKidsWelcome
        "
      ></div>
      <ng-template #someKidsWelcome>
        <span>Kids welcome for some locations</span>
      </ng-template>
      <ng-template #regularKidsWelcome>
        <span [ngClass]="{ 'not-available': !groupDetails.kidsWelcome }">
          Kids welcome
        </span>
      </ng-template>
    </li>
  </ul>
</div>
