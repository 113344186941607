<p class="site-header font-family-serif">
  Community:
  <span>Crossroads {{ meetingSite.site.name }}</span>
</p>

<table class="table group-table">
  <tr class="table-header">
    <th>Meeting Time</th>
    <th>Where</th>
    <th>Starts</th>
    <th>Weeks</th>
    <th>Childcare</th>
    <th>Who</th>
    <th>Register</th>
  </tr>
  <tbody>
    <tr
      *ngFor="let group of meetingSite.meetingDetails; trackBy: trackByGroup"
      [ngClass]="{ 'group-role': group?.currentParticipant?.groupRole?.id }"
    >
      <td data-label="Time">{{ group | fullDayMeetingTime }}</td>
      <td data-label="Where">{{ group | siteName }}</td>
      <td data-label="Starts">{{ group | startDate }}</td>
      <td data-label="Weeks">{{ group.duration || "Ongoing" }}</td>
      <td data-label="Childcare">
        <crds-icon
          *ngIf="group.childcareAvailable"
          name="check-circle"
          size="16"
          color="olive"
          class="hydrated"
        ></crds-icon>
        <span *ngIf="!group.childcareAvailable">No</span>
      </td>
      <td data-label="Who">{{ group | who }}</td>
      <td>
        <app-group-register [group]="group"></app-group-register>
      </td>
    </tr>
  </tbody>
</table>
