<div class="group-summary_container">
  <div class=" group-name push-top push-quarter-bottom" >{{ group.name }}</div>
  <div *ngIf="!!group.groupDetails.time" class="detail__container push-quarter-bottom">
    <crds-icon
      name="clock-o"
      size="16"
      color="orange-light"
      class="hydrated"
    ></crds-icon>
    <span *ngIf="!!group.groupDetails.frequency">
      {{ group.groupDetails.time }}, {{ group.groupDetails.frequency }}
    </span>
    <span *ngIf="!group.groupDetails.frequency">
      {{ group.groupDetails.time }}
    </span>
  </div>
  <div *ngIf="!!group.groupDetails.location" class="detail__container push-quarter-bottom">
    <crds-icon
      name="map-marker"
      size="16"
      color="rust"
      class="hydrated"
    ></crds-icon>
    <span>
      {{ group.groupDetails.location }}
    </span>
  </div>
  <div *ngIf="!!group.groupDetails.virtual" class="detail__container push-quarter-bottom">
    <crds-icon
      name="check-circle"
      size="16"
      color="olive"
      class="hydrated"
    ></crds-icon>
    <span>Virtual</span>
  </div>
</div>
