import { toBase64String } from '@angular/compiler/src/output/source_map';
import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import {
  currentGroup,
  currentGroupParticipants,
} from 'src/app/shared/state/state.resolver';
import { Toastr } from 'toastr';
import {
  GroupParticipantFragment,
  GroupViewFragment,
  RemoveParticipantFromGroupGQL,
} from 'src/generated/graphql';
import { ToastrService } from 'ngx-toastr';
import { cloneDeep } from '@apollo/client/utilities';

@Component({
  selector: 'app-group-remove-participant-modal',
  templateUrl: './group-remove-participant-modal.component.html',
  styleUrls: ['./group-remove-participant-modal.component.scss'],
})
export class GroupRemoveParticipantModalComponent implements OnInit {
  showModal: boolean = false;
  @Input() isLeader: boolean = false;
  @Input() groupParticipantId: string;
  @Input() userName: string = 'participant';
  removalReason: string;

  constructor(
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private removeGroupParticipant: RemoveParticipantFromGroupGQL
  ) {}

  ngOnInit(): void {
    if (!this.groupParticipantId) {
      throw new Error(
        'groupId and userId required for group-remove-participant-modal'
      );
    }
  }

  closeX = () => {
    this.showModal = false;
    this.cd.detectChanges();
  };

  closeModal(): void {
    this.showModal = false;
  }

  openModal(): void {
    this.showModal = true;
  }

  submitRemoveParticipant(removalReason: string): void {
    this.removeGroupParticipant
      .mutate({
        groupParticipantId: this.groupParticipantId,
        removalReason: removalReason,
      })
      .subscribe((result) => {
        let participants: GroupParticipantFragment[] = cloneDeep(
          currentGroupParticipants()
        );
        participants = participants.filter(
          (participant) =>
            participant.groupParticipantId != this.groupParticipantId
        );
        currentGroupParticipants(participants);
        this.closeModal();
        this.toastr.success('Participant Removed', 'Success!');
      });
  }
}
