import { Injectable } from '@angular/core';
import analytics from 'crds-analytics';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private analytics;

  constructor() {
    this.analytics = new analytics({
      appName: 'crds-groups',
      segmentWriteKey: environment.SEGMENT_WRITE_KEY
    });
  }

  public trackFocus(groupType, focus) {
    this.analytics.track('GroupFocus', {
      groupType: groupType,
      focus: focus
    });
  }

  public trackJoinGroupRequest(groupType, groupId) {
    this.analytics.track('JoinGroupRequest', {
      groupType: groupType,
      groupId: groupId
    });
  }

  public trackGroupRegister(groupType, groupId) {
    this.analytics.track('GroupRegister', {
      groupType: groupType,
      groupId: groupId
    });
  }

  public trackContactLeader(groupType, groupId, leaderId) {
    this.analytics.track('GroupContactLeader', {
      groupType: groupType,
      groupId: groupId,
      leaderId: leaderId
    });
  }

  public trackStep(groupType, stepNumber, stepName) {
    this.analytics.track('GroupStep', {
      groupType: groupType,
      stepNumber: stepNumber,
      stepName: stepName
    });
  }
}
