import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Constants } from 'src/app/app.constants';
import { GetGroupViewGQL } from 'src/generated/graphql';
import {
  currentGroup,
  currentUser,
  currentUserRole,
  isSuperAdmin,
  showPendingJoinRequestsModal,
} from '../../../shared/state/state.resolver';

@Injectable({
  providedIn: 'root',
})
export class GroupViewResolver implements Resolve<void> {
  groupView: Observable<void>;

  constructor(private getGroupView: GetGroupViewGQL, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<void> {
    return this.getGroupView
      .watch({ id: route.paramMap.get('groupId') }, { fetchPolicy: 'no-cache' })
      .valueChanges.pipe(
        map((results) => {
          const group = results.data.groups[0];
          const user = results.data.user;
          const isPrimaryContact: boolean =
            group?.primaryContact?.id === user?.id;

          const superAdmin =
            !!user &&
            user.securityRoles.filter(
              (role) => role.id === Constants.SuperAdminRole
            ).length > 0;

          if (
            group?.parentGroup?.id &&
            group?.parentGroup?.id !== Constants.OnsiteParentGroup &&
            !group?.currentParticipant?.groupRole &&
            !isPrimaryContact &&
            !superAdmin
          ) {
            this.router.navigateByUrl(`details/${group.parentGroup.id}`);
            return null;
          }

          const showPendingJoinModal =
            !!group?.pendingJoinRequests &&
            group?.pendingJoinRequests.length > 0;

          //store the results to the local state
          currentGroup(group);
          currentUser(user);
          currentUserRole(group?.currentParticipant?.groupRole);
          isSuperAdmin(superAdmin);
          showPendingJoinRequestsModal(showPendingJoinModal);
        }),
        take(1)
      );
  }
}
