import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { currentPendingJoinGroupRequests } from 'src/app/shared/state/state.resolver';
import {
  CurrentUserFragment,
  GetCurrentGroupGQL,
  GetCurrentPendingJoinGroupRequestsGQL,
  GetCurrentUserGQL,
  GetPendingJoinGroupRequestsGQL,
  GroupDetailsFragment,
  GroupRole,
  GroupViewFragment,
  JoinGroupRequestFragment,
  RequestToJoinGroupGQL,
} from 'src/generated/graphql';

@Component({
  selector: 'app-group-try-this-group',
  templateUrl: './group-try-this-group.component.html',
  styleUrls: ['./group-try-this-group.component.scss'],
})
export class GroupTryThisGroupComponent implements OnInit {
  groupDetails: Observable<GroupDetailsFragment>;
  showModal: boolean = false;
  showSignInModal: boolean = false;
  hasPendingRequest: Observable<boolean>;
  isLoadingUserData: boolean = false;

  group: Observable<GroupViewFragment>;
  currentUser: Observable<CurrentUserFragment>;
  currentPendingJoinRequests: Observable<JoinGroupRequestFragment[]>;

  constructor(
    private getCurrentGroup: GetCurrentGroupGQL,
    private getCurrentUser: GetCurrentUserGQL,
    private requestToJoinGroupMutation: RequestToJoinGroupGQL,
    private getPendingJoinGroupRequests: GetPendingJoinGroupRequestsGQL,
    private getCurrentPendingJoinRequests: GetCurrentPendingJoinGroupRequestsGQL,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.groupDetails = this.getCurrentGroup
      .watch()
      .valueChanges.pipe(
        map((result) => result.data.currentGroup?.groupDetails)
      );

    this.group = this.getCurrentGroup
      .watch()
      .valueChanges.pipe(map((result) => result.data.currentGroup));

    this.currentUser = this.getCurrentUser
      .watch()
      .valueChanges.pipe(map((result) => result.data.currentUser));

    this.currentUser
      .pipe(
        map((user) => {
          if (!user) {
            currentPendingJoinGroupRequests([]);
            this.isLoadingUserData = false;
            return null;
          }
        }),
        distinctUntilChanged((a, b) => {
          if (a === b) return true;
          if (a && b && a.id === b.id) return true;
          return false;
        }),
        switchMap(() => {
          this.isLoadingUserData = true;
          return this.getPendingJoinGroupRequests
            .watch({}, { fetchPolicy: 'no-cache' })
            .valueChanges.pipe(
              map((result) => {
                return currentPendingJoinGroupRequests(
                  result.data.user?.pendingJoinGroupRequests
                );
              })
            );
        })
      )
      .subscribe();

    this.currentPendingJoinRequests = this.getCurrentPendingJoinRequests
      .watch()
      .valueChanges.pipe(
        map((result) => {
          return result.data.currentPendingJoinGroupRequests;
        })
      );

    this.hasPendingRequest = combineLatest([
      this.currentPendingJoinRequests,
      this.group,
    ]).pipe(
      map((results) => {
        return !!results[0]?.some(
          (request) =>
            request.groupId === results[1].id &&
            !moment(request.submittedDate).isBefore(moment().subtract(7, 'd'))
        );
      }),
      tap((x) => (this.isLoadingUserData = false))
    );
  }

  closeX = () => {
    this.showModal = false;
    this.cd.detectChanges();
  };

  closeModal(): void {
    this.showModal = false;
  }

  openModal(
    currentUser: CurrentUserFragment,
    hasPendingRequest: boolean
  ): void {
    hasPendingRequest
      ? null
      : currentUser
      ? (this.showModal = true)
      : (this.showSignInModal = true);
  }

  onSignInSuccess = (): void => {
    this.showModal = true;
    this.cd.detectChanges();
  };

  onSignInClose = (): void => {
    this.showSignInModal = false;
    this.cd.detectChanges();
  };

  registerForGroup(user: CurrentUserFragment, group: GroupViewFragment): void {
    this.requestToJoinGroupMutation
      .mutate({
        groupJoinRequestInfo: {
          groupId: group.id,
          firstName: user.nickName,
          lastName: user.lastName,
          email: user.email
        },
      })
      .subscribe((result) => {
        const response: JoinGroupRequestFragment = {
          __typename: 'JoinGroupRequest',
          groupId: result.data.requestToJoinGroup.groupId,
          submittedDate: result.data.requestToJoinGroup.submittedDate,
        };

        currentPendingJoinGroupRequests([
          ...(currentPendingJoinGroupRequests()
            ? [...currentPendingJoinGroupRequests()]
            : []),
          response,
        ]);

        this.closeModal();
        this.toastr.success('Request To Join Sent', 'Sucess!');
        this.analytics.trackJoinGroupRequest(group.type, group.id);
      });
  }
}
