<div *ngIf="{ group: group | async, currentUser: currentUser | async } as data">
  <div *ngIf="!!data.group">
    <div *ngIf="!data.currentUser && showSignInModal">
      <app-signin-modal
        [showModal]="showSignInModal"
        [onClose]="onSignInClose"
        [onSuccess]="onSignInSuccess"
      ></app-signin-modal>
    </div>
    <div>
      <crds-button
        text="Contact leader"
        [color]="primary ? 'blue' : 'gray'"
        (click)="open(data.currentUser)"
        [display]="primary ? '' : 'outline'"
        [disabled]="!allowContactLeader"
      ></crds-button>
      <crds-modal
        label="Contact leader"
        [isActive]="showModal"
        [onModalClose]="close"
      >
        <app-group-summary-subheader
          [group]="data.group"
        ></app-group-summary-subheader>
        <hr class="push-ends" />
        <form [formGroup]="contactLeaderForm">
          <div class="form-group">
            <div class="row soft-half-sides">
              <div class="col soft-quarter-top soft-half-bottom">
                <input
                  type="text"
                  placeholder="Subject"
                  formControlName="subject"
                  class="form-control"
                />
                <div
                  *ngIf="
                    submitted &&
                    contactLeaderForm.controls['subject'].invalid &&
                    contactLeaderForm.controls['subject'].errors.required
                  "
                  class="error help-block"
                >
                  Subject Required
                </div>
              </div>
            </div>
            <div class="col">
              <textarea
                type="text"
                placeholder="Message"
                formControlName="message"
                class="form-control push-quarter-top"
              ></textarea>
              <div
                *ngIf="
                  submitted &&
                  contactLeaderForm.controls['message'].invalid &&
                  contactLeaderForm.controls['message'].errors.required
                "
                class="error help-block"
              >
                Message Required
              </div>
            </div>
          </div>
          <div
            class="push-top push-quarter-bottom"
            style="display: 'flex'; justify-content: 'flex-start'"
          >
            <crds-button
              color="cyan"
              text="Send"
              style="margin-right: '8px'"
              (click)="send(data.group)"
            ></crds-button>
            <crds-button
              color="gray-light"
              display="outline"
              text="Cancel"
              (click)="close()"
            ></crds-button>
          </div>
        </form>
      </crds-modal>
    </div>
  </div>
</div>
