import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { currentGroup } from 'src/app/shared/state/state.resolver';
import {
  CurrentUserFragment,
  GetCurrentGroupGQL,
  GetCurrentUserGQL,
  GroupViewFragment,
  InviteParticipantGQL,
  PendingInvitationFragment,
} from 'src/generated/graphql';
import { cloneDeep } from '@apollo/client/utilities';

@Component({
  selector: 'app-group-invite-participant',
  templateUrl: './group-invite-participant.component.html',
  styleUrls: ['./group-invite-participant.component.scss'],
})
export class GroupInviteParticipantComponent implements OnInit {
  group: Observable<GroupViewFragment>;
  currentUser: Observable<CurrentUserFragment>;
  showModal: boolean = false;
  submitted: boolean = false;
  allowSubmit: boolean = true;

  inviteParticipantForm = this.formBuilder.group({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    private getCurrentGroupQuery: GetCurrentGroupGQL,
    private getCurrentUserQuery: GetCurrentUserGQL,
    private inviteParticipantMutation: InviteParticipantGQL,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.group = this.getCurrentGroupQuery
      .watch()
      .valueChanges.pipe(map((result) => result.data.currentGroup));

    this.currentUser = this.getCurrentUserQuery
      .watch()
      .valueChanges.pipe(map((result) => result.data.currentUser));
  }

  open(): void {
    this.showModal = true;
  }

  close = () => {
    this.inviteParticipantForm.reset();
    this.submitted = false;
    this.showModal = false;
    this.cd.detectChanges();
  };

  save(group: GroupViewFragment, currentUser: CurrentUserFragment) {
    this.submitted = true;
    if (!this.inviteParticipantForm.valid) return;
    if (!this.allowSubmit) return;
    this.allowSubmit = false;

    this.inviteParticipantMutation
      .mutate({
        invitee: {
          ...this.inviteParticipantForm.value,
          groupId: group.id,
          groupName: group.name,
          leaderNickname: currentUser.nickName,
        },
      })
      .subscribe((result) => {
        const invitations = [
          ...(group?.pendingInvitations ? group?.pendingInvitations : []),
          <PendingInvitationFragment>{
            ...result.data.inviteNewGroupParticipant,
            __typename: 'GroupInvitee',
          },
        ];

        var updatedGroup: GroupViewFragment = cloneDeep(group);
        updatedGroup.pendingInvitations = invitations;

        currentGroup(updatedGroup);

        this.allowSubmit = true;
        this.close();
        this.toastr.success('Invited Participant', 'Success');
      });
  }
}
