import { InMemoryCache, makeVar } from '@apollo/client/core';
import {
  GroupRole,
  CurrentUserFragment,
  GroupViewFragment,
  InvitationFragment,
  JoinGroupRequestFragment,
  GroupFlowState,
  AdditionalServices,
  PendingGroupFragment,
  GroupSaveResponse,
  GroupParticipantFragment,
} from 'src/generated/graphql';
import {
  Constants,
  DefaultGroupFlowState,
  DefaultPendingGroup,
} from '../../app.constants';
import makeVarPersisted from './makeVarPersisted';

export const currentGroup = makeVar(<GroupViewFragment>{});
export const currentGroupParticipants = makeVar(<GroupParticipantFragment[]>[]);
export const isCurrentGroupAdmin = makeVar(<boolean>{});
export const isCrossroadsHostedAdmin = makeVar(<boolean>false);
export const isSuperAdmin = makeVar(<boolean>false);
export const isApprovedGroupLeader = makeVar(<boolean>false);
export const currentUserRole = makeVar(<GroupRole>{});
export const currentUser = makeVar(<CurrentUserFragment>{});
export const currentInvitation = makeVar(<InvitationFragment>{});
export const currentPendingJoinGroupRequests = makeVar(
  <JoinGroupRequestFragment[]>[]
);
export const showPendingJoinRequestsModal = makeVar(<boolean>false);
export const groupFlowState = makeVarPersisted(
  <GroupFlowState>DefaultGroupFlowState,
  'groupFlowState'
);
export const pendingGroup = makeVarPersisted(
  <PendingGroupFragment>DefaultPendingGroup,
  'pendingGroup'
);

export const originalGroup = makeVarPersisted(
  <PendingGroupFragment>DefaultPendingGroup,
  'originalGroup'
);

export const groupSaveResponse = makeVar(<GroupSaveResponse>null);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        currentGroup: {
          read() {
            return currentGroup();
          },
        },
        currentGroupParticipants: {
          read() {
            return currentGroupParticipants();
          },
        },
        isCrossroadsHostedAdmin: {
          read() {
            return isCrossroadsHostedAdmin();
          },
        },
        isSuperAdmin: {
          read() {
            return isSuperAdmin();
          },
        },
        isApprovedGroupLeader: {
          read() {
            return isApprovedGroupLeader();
          },
        },
        isCurrentGroupAdmin: {
          read() {
            return (
              currentUserRole()?.id == Constants.LeaderRoleId ||
              currentGroup()?.primaryContact?.id === currentUser()?.id ||
              isSuperAdmin()
            );
          },
        },
        currentUserRole: {
          read() {
            return currentUserRole();
          },
        },
        currentUser: {
          read() {
            return currentUser();
          },
        },
        currentInvitation: {
          read() {
            return currentInvitation();
          },
        },
        currentPendingJoinGroupRequests: {
          read() {
            return currentPendingJoinGroupRequests();
          },
        },
        showPendingJoinRequestsModal: {
          read() {
            return showPendingJoinRequestsModal();
          },
        },
        pendingGroup: {
          read() {
            return <PendingGroupFragment>{
              ...pendingGroup(),
              __typename: 'Group',
            };
          },
        },
        originalGroup: {
          read() {
            return <PendingGroupFragment>{
              ...originalGroup(),
              __typename: 'Group',
            };
          },
        },
        groupFlowState: {
          read() {
            return groupFlowState();
          },
        },
        groupSaveResponse: {
          read() {
            return groupSaveResponse();
          },
        },
      },
    },
  },
});
