<div class="pending-request__request col-xs-12">
  <div class="image-name_container col-sm-8">
    <div class="image-container soft-top">
      <crds-circle-image
        size="50"
        imageUrl="{{ request.participant.imageUrl }}"
      ></crds-circle-image>
    </div>
    <div class="user-info soft-half-top">
      <span class="normal"
        >{{ request.participant.nickName }}
        {{ request.participant.lastName }}</span
      >
      <div class="col participant-email">
        {{ request.participant.email }}
      </div>
    </div>
  </div>

  <div class="details">
    <div class="request-sent">
      <div class="date-requested">
        Request sent {{ request.requestDate | date: "shortDate" }}
      </div>
      <div class="col">
        <crds-button
          class="push-quarter-right"
          color="blue"
          display="solid"
          size="sm"
          text="Accept"
          (click)="accept()"
          [disabled]="disable"
        ></crds-button>
        <crds-button
          color="gray-light"
          display="outline"
          size="sm"
          text="Deny"
          (click)="deny()"
          [disabled]="disable"
        ></crds-button>
      </div>
    </div>
  </div>
</div>
