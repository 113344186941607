import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signin-modal',
  templateUrl: './signin-modal.component.html',
  styleUrls: ['./signin-modal.component.scss'],
})
export class SigninModalComponent implements OnInit {
  DEFAULT_AUTH_PROVIDER = environment.DEFAULT_AUTH_PROVIDER;
  @Input() showModal: boolean;
  @Input() onSuccess: Function;
  @Input() onClose: Function;

  constructor(private cd: ChangeDetectorRef, private router: Router) {}

  close = () => {
    this.showModal = false;
    this.cd.detectChanges();
    this.onClose();
  };

  handleSuccess = () => {
    this.showModal = false;
    this.cd.detectChanges();
    this.router.navigate([`${this.router.url}`]);
    this.onSuccess();
  };

  ngOnInit(): void {}
}
