import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import {
  CurrentUserFragment,
  CurrentUserFragmentDoc,
  GetCurrentGroupGQL,
  GetCurrentUserGQL,
  GroupMeetingSitesFragment,
} from 'src/generated/graphql';
import { MeetingSite } from './group-meeting-sites.types';

@Component({
  selector: 'app-group-meeting-sites',
  templateUrl: './group-meeting-sites.component.html',
  styleUrls: ['./group-meeting-sites.component.scss'],
})
export class GroupMeetingSitesComponent implements OnInit {
  meetingSites: Observable<MeetingSite[]>;

  constructor(private getCurrentGroup: GetCurrentGroupGQL,
    private getCurrentUser: GetCurrentUserGQL) {}

  ngOnInit(): void {
    let group = this.getCurrentGroup.watch().valueChanges.pipe(
      map((result) => result.data.currentGroup),
      distinctUntilChanged()
    );

    let user = this.getCurrentUser.watch().valueChanges.pipe(
      map((result) => result.data.currentUser)
    );

    this.meetingSites = 
      combineLatest([group, user]).pipe(
        map(([group, user]) =>this.groupBy(group, user))
      );
  }

  trackBySites(index, meetingSite: MeetingSite) {
    return meetingSite.site.id;
  }

  groupBy(group: GroupMeetingSitesFragment, user: CurrentUserFragment): MeetingSite[] {
    if (!group.subGroups) return [];

    let groupedBySite = group.subGroups.reduce((acc, subGroup) => {
      let isLeader = subGroup.leaders.find((l) => l.id === user?.id);
      let isPrimaryContact = subGroup.primaryContact.id === user?.id;
      if (!subGroup.availableOnline && !isLeader && !isPrimaryContact)
        return acc;

      if (!acc[subGroup.site.id]) {
        acc[subGroup.site.id] = [];
      }

      acc[subGroup.site.id].push(subGroup);

      return acc;
    }, {});

    let meetingSites = Object.entries(groupedBySite).map(([id, subGroup]) => {
      return <MeetingSite>{
        site: {
          id: id,
          name: subGroup[0].site.name,
        },
        meetingDetails: subGroup,
      };
    });

    return meetingSites;
  }
}