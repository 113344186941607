import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CurrentUserFragment,
  GetCurrentGroupGQL,
  GetCurrentUserGQL,
  GroupViewFragment,
  LeaveGroupGQL,
} from 'src/generated/graphql';

@Component({
  selector: 'app-leave-group',
  templateUrl: './leave-group.component.html',
  styleUrls: ['./leave-group.component.scss'],
})
export class LeaveGroupComponent implements OnInit {
  @Input() type: string;
  @Input() isLeader: boolean = false;
  showModal: boolean = false;
  group: Observable<GroupViewFragment>;
  currentUser: Observable<CurrentUserFragment>;

  constructor(
    private getCurrentGroup: GetCurrentGroupGQL,
    private cd: ChangeDetectorRef,
    private leaveGroup: LeaveGroupGQL,
    private toastr: ToastrService,
    private getCurrentUser: GetCurrentUserGQL,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.group = this.getCurrentGroup
      .watch()
      .valueChanges.pipe(map((result) => result.data.currentGroup));

    this.currentUser = this.getCurrentUser
      .watch()
      .valueChanges.pipe(map((result) => result.data.currentUser));
  }

  open(): void {
    this.showModal = true;
  }

  leave(user: CurrentUserFragment, group: GroupViewFragment) {
    this.leaveGroup
      .mutate({
        leaveGroupInput: {
          groupParticipantId: group.currentParticipant.groupParticipantId,
          nickName: user.nickName,
          lastName: user.lastName,
          email: user.email,
          groupName: group.name,
          groupId: group.id,
        },
      })
      .subscribe(() => {
        this.close();
        this.toastr.success(
          'You have successfully left this group!',
          'Success'
        );
        this.router.navigate([`/${group.id}`]);
      });
  }

  close = () => {
    this.showModal = false;
    this.cd.detectChanges();
  };
}
