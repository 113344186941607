import { AuthConfig } from '@crds_npm/crds-angular-auth-wrapper-lib/lib/models/AuthConfig';
import { environment } from '../../environments/environment';

export const AUTH_CONFIG: AuthConfig = {
  baseApiUrl: environment.CRDS_GQL_ENDPOINT,
  authRedirectUrl: environment.CRDS_NET_ENDPOINT + '/signin',
  RedirectUri: window.location.origin,
  cookieDomain: environment.CRDS_NET_ENDPOINT,
  crdsEnv: environment.CRDS_ENV,
  OKTA_URL: environment.OKTA_OAUTH_BASE_URL,
  OKTA_CLIENT_ID: environment.OKTA_CLIENT_ID,
};


