import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  showPendingJoinRequestsModal,
} from 'src/app/shared/state/state.resolver';
import {
  CurrentUserFragment,
  GetCurrentGroupGQL,
  GetCurrentGroupPartcipantsGQL,
  GetCurrentUserGQL,
  GetShowPendingJoinRequestsModalGQL,
  GroupParticipantFragment,
  GroupViewFragment,
  JoinRequestFragment,
} from 'src/generated/graphql';

@Component({
  selector: 'app-group-leader-action-required',
  templateUrl: './group-leader-action-required.component.html',
  styleUrls: ['./group-leader-action-required.component.scss'],
})
export class GroupLeaderActionRequiredComponent implements OnInit {
  currentUser: Observable<CurrentUserFragment>;
  group: Observable<GroupViewFragment>;
  pendingJoinRequests: Observable<JoinRequestFragment[]>;
  showPendingRequestsModal: Observable<boolean>;
  participants: Observable<GroupParticipantFragment[]>;

  constructor(
    private getCurrentGroup: GetCurrentGroupGQL,
    private getCurrentUserQuery: GetCurrentUserGQL,
    private getCurrentGroupParticipants: GetCurrentGroupPartcipantsGQL,
    private getShowPendingJoinRequestsModal: GetShowPendingJoinRequestsModalGQL,
  ) {}

  ngOnInit(): void {
    this.currentUser = this.getCurrentUserQuery
      .watch()
      .valueChanges.pipe(map((result) => result.data.currentUser));

    this.participants = this.getCurrentGroupParticipants
      .watch()
      .valueChanges.pipe(
        map((result) => {
          return result.data.currentGroupParticipants;
        })
      );

    this.group = this.getCurrentGroup
      .watch()
      .valueChanges.pipe(map((result) => result.data.currentGroup));

    this.pendingJoinRequests = this.getCurrentGroup
      .watch()
      .valueChanges.pipe(
        map((result) => result.data.currentGroup.pendingJoinRequests)
      );

    this.showPendingRequestsModal = this.getShowPendingJoinRequestsModal
      .watch()
      .valueChanges.pipe(
        map((result) => {
          return result.data.showPendingJoinRequestsModal;
        })
      );
  }

  close(): void {
    showPendingJoinRequestsModal(false);
  }
}
