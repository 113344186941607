import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ChangeGroupRoleGQL,
  GetCurrentGroupGQL,
  GroupParticipant,
  GroupParticipantFragment,
  GroupViewFragment,
} from 'src/generated/graphql';
import {
  currentGroup,
  currentGroupParticipants,
} from 'src/app/shared/state/state.resolver';
import { cloneDeep } from '@apollo/client/utilities';
import { Constants } from 'src/app/app.constants';

@Component({
  selector: 'app-group-edit-member-role',
  templateUrl: './group-edit-member-role.component.html',
  styleUrls: ['./group-edit-member-role.component.scss'],
})
export class GroupEditMemberRoleComponent implements OnInit {
  showModal: boolean = false;
  leaderRoleId: number = 22;
  memberRoldId: number = 16;
  leaderApprovalStatusNumber: number = 4;
  group: Observable<GroupViewFragment>;

  @Input() isLeader: boolean = false;
  @Input() userName: string = 'participant';
  @Input() groupParticipantId: string;
  @Input() leaderApprovalStatus: number; // 4 is approved
  @Input() groupRoleId: number;

  constructor(
    private getCurrentGroup: GetCurrentGroupGQL,
    private changeGroupRole: ChangeGroupRoleGQL,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.group = this.getCurrentGroup
      .watch()
      .valueChanges.pipe(map((result) => result.data.currentGroup));
  }

  closeX = () => {
    this.showModal = false;
    this.cd.detectChanges();
  };

  closeModal(): void {
    this.showModal = false;
  }

  openModal(): void {
    this.showModal = true;
  }

  submitRole(setLeader: { role: string }): void {
    const roleId =
      setLeader.role.toUpperCase() === 'member'.toUpperCase()
        ? this.memberRoldId
        : this.leaderRoleId;
    this.changeGroupRole
      .mutate({
        groupParticipantId: this.groupParticipantId,
        roleId: roleId.toString(),
      })
      .subscribe((result) => {
        const participants = cloneDeep(currentGroupParticipants());
        const updatedParticipants = participants.map((participant) => {
          if (participant.groupParticipantId === this.groupParticipantId) {
            let updatedParticipant = { ...participant };
            updatedParticipant.groupRole = {
              ...participant.groupRole,
              id: roleId,
              name: setLeader.role,
            };
            return updatedParticipant;
          }
          return participant;
        });

        const leaders = updatedParticipants.
          filter(p => p.groupRole.id === Constants.LeaderRoleId).
          map(p => ({
            __typename: "GroupParticipant", 
            id: p.id,
            imageUrl: p.imageUrl,
            nickName: p.nickName,
            lastName: p.lastName
          }));

        currentGroupParticipants(updatedParticipants);
        currentGroup({
          ...currentGroup(),
          leaders: <any>leaders,
        });

        this.closeModal();
        this.toastr.success('Role Updated', 'Success!');
      });
  }
}
