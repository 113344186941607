import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SigninModalComponent } from './signin-modal/signin-modal.component';
import { AnalyticsService } from './services/analytics.service';

@NgModule({
  declarations: [SigninModalComponent],
  imports: [CommonModule],
  exports: [SigninModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [AnalyticsService,],
})
export class SharedModule {}
