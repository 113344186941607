<div
  *ngIf="{
    pendingJoinRequests: pendingJoinRequests | async,
    currentUser: currentUser | async,
    group: group | async,
    showPendingRequestsModal: showPendingRequestsModal | async,
    participants: participants | async
  } as data"
>
  <div *ngIf="data.showPendingRequestsModal">
    <crds-modal
      [label]="
        data.pendingJoinRequests?.length === 0
          ? 'NICE WORK!'
          : 'LEADER ACTION REQUIRED'
      "
      [isActive]="data.showPendingRequestsModal"
      [preventClose]="true"
      [appendToBody]="false"
    >
      <div
        class="flush-top text-gray-light soft-ends"
        *ngIf="
          data.pendingJoinRequests?.length > 0;
          then pending;
          else noPending
        "
      ></div>
      <ng-template #pending>
        People want to join your group! Please respond to all requests —  it’s polite! If you need to deny a request, we will send an email stating that your group is not adding new members at this time, and point them in the direction of some alternative options. 
      </ng-template>
      <ng-template #noPending>
        You have resolved all pending requests.  High five!.  Nothing more to do at
        this moment.
      </ng-template>

      <div
        class="pending-requests row soft-ends"
        *ngFor="let joinRequest of data.pendingJoinRequests"
      >
        <app-accept-deny-request 
          [currentUser]="data.currentUser"
          [group]="data.group"
          [participants]="data.participants"
          [request]="joinRequest"></app-accept-deny-request>
      </div>

      <div
        class="push-half-top push-quarter-bottom"
        style="display: 'flex'; justify-content: 'flex-start'"
      >
        <crds-button
          color="blue"
          display="solid"
          text="Exit"
          (click)="close()"
        ></crds-button>
      </div>
    </crds-modal>
  </div>
</div>
