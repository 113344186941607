import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GroupViewComponent } from './features/group-view/group-view.component';
import { GroupViewResolver } from './features/group-view/resolver/group-view.resolver';

const routes: Routes = [
  {
    path: 'details/:groupId',
    component: GroupViewComponent,
    resolve: {
      groupDescription: GroupViewResolver,
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'invitation',
    loadChildren: () =>
      import('./features/invitations/invitations.module').then(
        (m) => m.InvitationsModule
      ),
  },
  // This is for /create and /edit routes. Since both depend on the same module
  // then we need to have this as the "catch all" that routes to the create/edit flow.
  {
    path: 'create',
    loadChildren: () =>
      import('./features/create-edit/create-edit-group.module').then(
        (m) => m.CreateEditGroupModule
      ),
  },
  {
    path: 'edit',
    loadChildren: () =>
      import('./features/create-edit/create-edit-group.module').then(
        (m) => m.CreateEditGroupModule
      ),
  },
  {
    path: '**',
    component: null,
    resolve: {
      url: '404resolver',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
