import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from 'src/app/app.constants';
import { groupSaveResponse } from 'src/app/shared/state/state.resolver';
import {
  GetCurrentGroupGQL,
  GetGroupSaveResponseGQL,
  GroupCreationStatus,
  GroupDetailsFragment,
  GroupSaveResponse,
  GroupViewFragment,
} from 'src/generated/graphql';

@Component({
  selector: 'app-group-save-details',
  templateUrl: './group-save-details.component.html',
  styleUrls: ['./group-save-details.component.scss'],
})
export class GroupSaveDetailsComponent implements OnInit {
  groupSaveResponse: Observable<GroupSaveResponse>;
  currentGroup: Observable<GroupViewFragment>;
  groupCreationStatus = GroupCreationStatus;
  isHosted: Observable<boolean>;

  constructor(
    public getGroupSaveResponse: GetGroupSaveResponseGQL,
    private getCurrentGroup: GetCurrentGroupGQL
  ) {}

  ngOnInit(): void {
    this.groupSaveResponse = this.getGroupSaveResponse
      .watch()
      .valueChanges.pipe(map((results) => results.data.groupSaveResponse));

    this.currentGroup = this.getCurrentGroup
      .watch()
      .valueChanges.pipe(map((result) => result.data.currentGroup));

    this.isHosted = this.currentGroup.pipe(
      map((group) => group.type.id === Constants.HostedGroupTypeId)
    );
  }

  close() {
    groupSaveResponse(null);
  }
}

@Pipe({ name: 'saveResponseTitle' })
export class SaveResponseTitlePipe implements PipeTransform {
  transform(
    currentGroup: GroupViewFragment,
    groupSaveResponse: GroupSaveResponse
  ): string {
    if (groupSaveResponse.isEdit) return 'Your Group has been updated';
    if (currentGroup.availableOnline) return 'Your group has been created';
    return 'Your group has been saved';
  }
}

@Pipe({ name: 'groupHasStatus' })
export class GroupHasStatusPipe implements PipeTransform {
  transform(
    statuses: GroupCreationStatus[],
    status: GroupCreationStatus
  ): boolean {
    return statuses.includes(status);
  }
}
