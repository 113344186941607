import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  CurrentUserFragment,
  CurrentUserRoleGQL,
  GetCurrentGroupGQL,
  GetCurrentGroupPartcipantsGQL,
  GetCurrentUserGQL,
  GetGroupParticipantsGQL,
  GroupParticipantFragment,
  GroupRole,
  GroupViewFragment,
  IsCurrentGroupAdminGQL,
} from 'src/generated/graphql';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { getUserBackgroundColor } from 'src/app/app.constants';
import {
  currentGroup,
  currentGroupParticipants,
} from 'src/app/shared/state/state.resolver';
import moment from 'moment';

@Component({
  selector: 'app-group-participants',
  templateUrl: './group-participants.component.html',
  styleUrls: ['./group-participants.component.scss'],
})
export class GroupParticipantsComponent implements OnInit {
  participants: Observable<
    (GroupParticipantFragment & { backgroundColor: string })[]
  >;
  hasAvatarImg: Observable<boolean>;
  group: Observable<GroupViewFragment>;
  isCurrentGroupAdmin: Observable<boolean>;
  currentUserRole: Observable<GroupRole>;
  participantEmails: Observable<string>;
  contactOptions: { [key: string]: Function };
  currentUser: Observable<CurrentUserFragment>;

  constructor(
    private isCurrentGroupAdminQuery: IsCurrentGroupAdminGQL,
    private getCurrentGroupQuery: GetCurrentGroupGQL,
    private getCurrentUser: GetCurrentUserGQL,
    private getGroupParticipants: GetGroupParticipantsGQL,
    private getCurrentGroupParticipants: GetCurrentGroupPartcipantsGQL,
    private currentUserRoleQuery: CurrentUserRoleGQL,
    private clipboardService: ClipboardService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.clipboardService.copyResponse$.subscribe((re) => {
      if (re.isSuccess) {
        this.toastr.success('Emails successfully copied to your clipboard');
      }
    });

    this.isCurrentGroupAdmin = this.isCurrentGroupAdminQuery
      .watch()
      .valueChanges.pipe(map((result) => result.data.isCurrentGroupAdmin));

    this.currentUserRole = this.currentUserRoleQuery
      .watch()
      .valueChanges.pipe(map((result) => result.data.currentUserRole));

    this.currentUser = this.getCurrentUser.watch().valueChanges.pipe(
      map((result) => {
        return result.data.currentUser;
      })
    );

    this.group = this.getCurrentGroupQuery
      .watch()
      .valueChanges.pipe(map((result) => result.data.currentGroup));

    this.getGroupParticipants
      .watch({ id: currentGroup().id })
      .valueChanges.pipe(
        tap((result) =>
          currentGroupParticipants(result.data.groups[0]?.participants)
        )
      )
      .subscribe();

    this.participants = this.getCurrentGroupParticipants
      .watch()
      .valueChanges.pipe(
        map((result) => {
          return result.data.currentGroupParticipants
            ?.slice()
            .map((p) => {
              return {
                ...p,
                backgroundColor: getUserBackgroundColor(parseInt(p.id)),
              };
            })
            .sort(
              (a, b) =>
                (b.groupRole.name == 'Leader' ? 1 : 0) -
                (a.groupRole.name == 'Leader' ? 1 : 0)
            );
        })
      );

    this.participantEmails = this.participants.pipe(
      map((participants) =>
        participants.map((participant) => participant.email).join(';')
      )
    );

    this.initContactParticipantOptions();
  }

  initContactParticipantOptions() {
    this.contactOptions = {
      'Copy email addresses': () => {
        this.participantEmails.subscribe((participantEmails) => {
          this.clipboardService.copy(participantEmails);
        });
      },
      'Email participants': () => {
        this.participantEmails.subscribe((participantEmails) => {
          window.location.href = `mailto:${participantEmails}`;
        });
      },
    };
  }
}

@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform {
  transform(date: number): string {
    var newDate = moment.utc(date * 1000);
    return newDate.format('MM/DD/YYYY');
  }
}
