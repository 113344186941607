<div
  *ngIf="{
    group: group | async,
    currentUser: currentUser | async,
    hasPendingRequest: hasPendingRequest | async
  } as data"
>
  <div *ngIf="!!data.group && data.hasPendingRequest !== undefined">
      <crds-tooltip
        [isActive]="data.hasPendingRequest"
        position="bottom"
        tooltip="You already sent a request to try this group! You should hear back from a leader within 7 days - If not, click Contact Leader to send them a message, or let us know at groups@crossroads.net!"
      >
        <crds-button
          slot="target"
          [disabled]="data.hasPendingRequest"
          color="blue"
          (click)="openModal(data.currentUser, data.hasPendingRequest)"
          text="Try this group"
        ></crds-button>
      </crds-tooltip>
    <div *ngIf="!data.currentUser">
      <app-signin-modal
        [showModal]="showSignInModal"
        [onClose]="onSignInClose"
        [onSuccess]="onSignInSuccess"
      ></app-signin-modal>
    </div>
    <div *ngIf="!!data.currentUser && !data.hasPendingRequest && !isLoadingUserData ">
      <crds-modal
        label="Request To Join"
        [isActive]="showModal"
        [onModalClose]="closeX"
        [appendToBody]="false"
      >
        <app-group-summary-subheader
          [group]="data.group"
        ></app-group-summary-subheader>
        <hr />
        <p class="text-gray-light">
          We'll send a request to this group's leader that you'd like to join and
          they'll follow up with you.
        </p>
        <p class="text-gray-light">Sound good?</p>
        <div class="push-top push-quarter-bottom" style="display: flex">
          <crds-button
            color="cyan"
            text="Yes!"
            style="margin-right: 8px"
            (click)="registerForGroup(data.currentUser, data.group)"
          ></crds-button>
          <crds-button
            color="gray-light"
            display="outline"
            text="Cancel"
            (click)="closeModal()"
          ></crds-button>
        </div>
      </crds-modal>
    </div>
  </div>
</div>
