<div *ngIf="pendingInvitations | async as pendingInvitations">
  <div *ngFor="let invitee of pendingInvitations">
    <li>
      <div class="invited-participant-card push-half-bottom">
        <div class="icon-container">
          <svg id="envelope" width="31px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 384">
            <g>
              <path d="M464,64H48A48.05,48.05,0,0,0,0,112V400a48.05,48.05,0,0,0,48,48H464a48.05,48.05,0,0,0,48-48V112A48.05,48.05,0,0,0,464,64Zm0,32a15.86,15.86,0,0,1,6.13,1.24L256,282.83,41.87,97.24A15.86,15.86,0,0,1,48,96Zm0,320H48a16,16,0,0,1-16-16V131.05l213.52,185a16,16,0,0,0,21,0L480,131.05V400A16,16,0,0,1,464,416Z" transform="translate(0 -64)"/>
            </g>
          </svg>
        </div>
        <div class="crds-card-spacing">
          <div class="crds-left">
            <span>Invitation sent to</span>
            <br>
            <span class="invitee-email">{{ invitee.email }}</span>
          </div>
          <div class="crds-right">
            <a class="pointer" (click)="cancelInvite(invitee, pendingInvitations)">Cancel invitation</a>
          </div>
        </div>
      </div>
    </li>
  </div>
</div>
