export const environment = {
  production: true,
  SEGMENT_WRITE_KEY: 'OI0Vv8TUJ6RRt5RoPlI8ppNEmQxcuDCZ',
  CRDS_GQL_ENDPOINT: 'https://api-int.crossroads.net/graphql-gateway',
  CRDS_NET_ENDPOINT: 'https://int.crossroads.net',
  CRDS_ENV: 'int',
  OKTA_OAUTH_BASE_URL: 'https://authpreview.crossroads.net',
  OKTA_CLIENT_ID: '0oahgpg7elMxVJedi0h7',
  DEFAULT_AUTH_PROVIDER:  'Okta',
  GROUPS_END_DATE: '7/31/2023',
};
