<a
[ngClass]="{ 'leader': isLeader === true }"
(click)="openModal()">Edit</a>
<crds-modal class="modal-color-body" [appendToBody]="false" label="Edit Participant" [isActive]="showModal" [onModalClose]="closeX">
    <div class="flush-top text-gray-light" style="margin-bottom: 2em">
        Indicate <span class="username">{{ userName }}'s</span> role in the group.
    </div>
    <label class="control-label block">Role</label>

    <form #roleForm="ngForm" novalidate>
      <div class="tooltip">
        <label 
          class="control radio" 
          [ngClass]="{'disabled' : leaderApprovalStatus !== 4}"
          style="display: inline-block;"
          >
          <input 
            id="radio1" 
            name="role" 
            type="radio" 
            [attr.disabled]="leaderApprovalStatus !== 4 ? '' : null"
            [value]="groupRoleId === leaderRoleId ? '' : 'Leader'" ngModel
            >
          <span class="control-indicator"></span>
          Leader
        </label>
        
        <div *ngIf="leaderApprovalStatus !== 4" class="tooltiptext">
          <div class="tooltipinner"></div>
          <i>This person is not an approved group leader.</i>
        </div>
      </div>
    
      <label class="control radio">
        <input id="radio2" name="role" type="radio" [value]="groupRoleId === memberRoldId ? '' : 'Member'" ngModel>
        <span class="control-indicator"></span>
        Participant
      </label>
    
      <div class="button-container push-quarter-bottom" style="display: flex">
        <crds-button type="submit" color="cyan" text="Save" style="margin-right: 8px" (click)="submitRole(roleForm.value)">
        </crds-button>
        <crds-button color="gray-light" display="outline" text="Cancel" (click)="closeModal()">
        </crds-button>
      </div>
    </form>
</crds-modal>
