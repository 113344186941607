import { group } from '@angular/animations';
import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Constants } from 'src/app/app.constants';
import { SubgroupFragment, WaitingListFragment } from 'src/generated/graphql';
import { MeetingSite } from '../group-meeting-sites.types';

@Component({
  selector: 'app-group-meeting-site',
  templateUrl: './group-meeting-site.component.html',
  styleUrls: ['./group-meeting-site.component.scss'],
})
export class GroupMeetingSiteComponent implements OnInit {
  @Input() meetingSite: MeetingSite;
  LeaderRoleId: number = Constants.LeaderRoleId;

  constructor() {}

  ngOnInit(): void {}

  trackByGroup(index, group: SubgroupFragment) {
    return (
      group.id +
      group.currentParticipant?.groupRole +
      group.subGroups.find(() => true)?.currentParticipant?.groupRole
    );
  }
}

@Pipe({ name: 'startDate' })
export class StartDatePipe implements PipeTransform {
  transform(group: SubgroupFragment): string {
    if (!group.duration) return 'Ongoing';

    var now = moment.utc(new Date());
    var start = moment.utc(group.startDate * 1000);

    return start.format('MMM DD');
  }
}

@Pipe({ name: 'siteName' })
export class SiteNamePipe implements PipeTransform {
  transform(group: SubgroupFragment): string {
    if (group.meetsOnline) return 'Virtual';
    return `${group.site.name} Site`;
  }
}

@Pipe({ name: 'fullDayMeetingTime' })
export class FullDayMeetingTimePipe implements PipeTransform {
  transform(group: SubgroupFragment): string {
    let meetingInfo = group.meetingSchedule;
    if (!meetingInfo?.day) return 'No set time (Marco Polo)';
    return `${this.day(meetingInfo.day?.name)}${this.hourFormat(
      meetingInfo.time
    )}${
      meetingInfo.endTime ? ' -' + this.hourFormat(meetingInfo.endTime) : ''
    }${this.frequency(meetingInfo.frequency?.name)}`;
  }

  private day(day: string): string {
    if (!day) return '';
    return day.substring(0, 3);
  }

  private hourFormat(time: string): string {
    if (!time) return '';

    let timeslice = time.split(':');
    if (timeslice[1] === '00') return ` ${moment(time, 'h:mm a').format('ha')}`;

    return ` ${moment(time, 'h:mm a').format('h:mma')}`;
  }

  private frequency(frequency: string): string {
    if (!frequency) return '';

    return `, ${frequency.toLowerCase()}`;
  }
}

@Pipe({ name: 'who' })
export class WhoPipe implements PipeTransform {
  SchoolAgeRange: string[] = [
    'Middle School Students (Grades 6-8)',
    'High School Students (Grades 9-12)',
    'College Students',
  ];

  transform(group: SubgroupFragment): string {
    const demographics = this.demographic(group);
    const ageGroups = this.ageGroups(group);

    if (!ageGroups) return demographics;

    return `${demographics}, ${ageGroups}`;
  }

  private demographic(group: SubgroupFragment): string {
    return group.groupDetails.demographic;
  }

  private ageGroups(group: SubgroupFragment): string {
    return group.groupDetails.ageRange;
  }
}
